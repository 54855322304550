import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { shadeColor } from "../../../utils/utils";
import { ArrowRightAltOutlined } from "@mui/icons-material";

const AccountTransferMobile = (props) => {
  const { transferTransactions, handleEdit } = props;
  const currency = useSelector((state) => state.userSettings.currency);
  const accounts = useSelector((state) => state.accounts.userAccounts);
  const isDarkMode = useSelector((state) => state.userSettings.isDarkMode);
  const [unitsLoaded, setUnitsLoaded] = useState(10);
  const [dataByDate, setDataByDate] = useState([]);
  const [loadedData, setLoadedData] = useState([]);
  //   const [searchFilter, setSearchFilter] = useState("");

  const handleScroll = () => {
    if (
      unitsLoaded < dataByDate.length &&
      window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
    ) {
      setUnitsLoaded((prevState) => prevState + 5);
    }
  };

  //   const handleSearchFilterInput = (e) => {
  //     let filter = e.target.value?.toLowerCase();
  //     setSearchFilter(filter);
  //   };

  const filterData = () => {
    // filter and paginates the data
    let groupedData = [];
    transferTransactions.reduce((obj, item) => {
      //   if (
      //     item.description?.toLowerCase().includes(searchFilter) ||
      //     item.vendor?.toLowerCase().includes(searchFilter) ||
      //     item.category?.toLowerCase().includes(searchFilter)
      //   ) {
      // }

      const dateFormatted = new Date(
        item.date.seconds * 1000
      ).toLocaleDateString(undefined, { timeZone: "UTC" });
      const dateFormat = new Date(item.date.seconds * 1000).toLocaleDateString(
        "en-US",
        {
          timeZone: "UTC",
          year: "numeric",
          month: "short",
          day: "numeric",
        }
      );

      if (!obj[dateFormatted]) {
        obj[dateFormatted] = {
          date: dateFormatted,
          dateFormat: dateFormat,
          // totalAmount: 0,
          items: [],
        };
        groupedData.push(obj[dateFormatted]);
      }
      // obj[dateFormatted].totalAmount += item.amount;
      obj[dateFormatted].items.push(item);

      return obj;
    }, {});

    setDataByDate(groupedData);
  };

  useEffect(() => {
    // upon page load instantly paginates the data
    // else if there is filter then delay 500ms
    // if (searchFilter.length > 0) {
    //   const delayFilter = setTimeout(() => {
    //     filterData();
    //   }, 500);
    //   return () => clearTimeout(delayFilter);
    // } else {
    // }
    filterData();
  }, [
    transferTransactions,
    //searchFilter
  ]);

  //pagination
  useEffect(() => {
    setLoadedData(dataByDate.slice(0, unitsLoaded));
  }, [dataByDate, unitsLoaded]);

  // event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [unitsLoaded, dataByDate]);

  const variants = {
    initial: { opacity: 0, y: 15 },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        duration: 0.4,
        ease: [0.36, 0.66, 0.04, 1],
      },
    },
    exit: {
      opacity: 0,
      y: 15,
      transition: {
        duration: 0.3,
        ease: [0.36, 0.66, 0.04, 1],
      },
    },
  };
  return (
    <div className="accounttransfermobile">
      {/* <input
        id="search-input"
        name="search-input"
        type="text"
        value={searchFilter}
        onChange={handleSearchFilterInput}
        className="searchFilterInput"
        placeholder="Search"
      /> */}
      <div>
        <AnimatePresence initial={false}>
          {loadedData.map((dayTransactions) => {
            return (
              <motion.div
                layout
                initial="initial"
                animate="enter"
                exit="exit"
                variants={variants}
                className="dateGroup"
                key={dayTransactions.date}
              >
                <div className="dateWrapper">
                  <div className="date">{dayTransactions.dateFormat}</div>
                  {/* <div className="total">
                    <div className="currency">{currency.symbol}</div>
                    <span className="totalAmount">
                      {Number(dayTransactions.totalAmount).toLocaleString(
                        "en-US"
                      )}
                    </span>
                  </div> */}
                </div>
                <div className="dateGroupCard">
                  {dayTransactions.items.map((transaction) => {
                    // const category = userCategories.find(
                    //   (item) => item.label === transaction.category
                    // );
                    const transferFromAccount = accounts.find(
                      (item) => item.id === transaction.accountFrom
                    );

                    const transferToAccount = accounts.find(
                      (item) => item.id === transaction.accountTo
                    );

                    // const project = projects.find(
                    //   (project) => project.id === transaction.sheetId
                    // );

                    return (
                      <motion.li
                        initial="initial"
                        animate="enter"
                        exit="exit"
                        variants={variants}
                        layout
                        className="item"
                        key={transaction.id}
                        onClick={() => handleEdit(transaction.id)}
                      >
                        <div className="itemWrapper">
                          <div className="left">
                            {/* {category && (
                              <CategoryIcon
                                Icon={category.icon}
                                backgroundColor={category.color}
                                imageSource={category.imageSource}
                              />
                            )} */}
                            <div className="info">
                              {/* <div className="title">
                                {transaction.vendor
                                  ? transaction.vendor
                                  : "N/A"}
                              </div> */}
                              {/* {project && (
                                <div className="projectTitle">
                                  <span className="projectIcon"></span>
                                  {project.name}
                                </div>
                              )} */}
                              {/* <div className="details">
                                {transaction.category && (
                                  <span className="vendor">
                                    {transaction.category + " - "}
                                  </span>
                                )}
                                {transaction.description}
                              </div> */}
                              {transferFromAccount && (
                                <div
                                  className="account"
                                  style={{
                                    color: transferFromAccount?.color
                                      ? shadeColor(
                                          transferFromAccount?.color,
                                          isDarkMode ? 20 : -20
                                        )
                                      : "",
                                    backgroundColor:
                                      transferFromAccount?.color + "1A",
                                    border: `1px solid ${transferFromAccount?.color}33`,
                                  }}
                                >
                                  {transferFromAccount.name}
                                </div>
                              )}
                              <div className="transferIcon">
                                <ArrowRightAltOutlined />
                              </div>
                              {transferToAccount && (
                                <div
                                  className="account"
                                  style={{
                                    color: transferToAccount?.color
                                      ? shadeColor(
                                          transferToAccount?.color,
                                          isDarkMode ? 20 : -20
                                        )
                                      : "",
                                    backgroundColor:
                                      transferToAccount?.color + "1A",
                                    border: `1px solid ${transferToAccount?.color}33`,
                                  }}
                                >
                                  {transferToAccount.name}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="right">
                            <div className="currency">{currency.symbol}</div>
                            <div className="amount">
                              {Number(transaction.amount).toLocaleString(
                                "en-US"
                              )}
                            </div>
                          </div>
                        </div>
                      </motion.li>
                    );
                  })}
                </div>
              </motion.div>
            );
          })}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default AccountTransferMobile;
