import { Close } from "@mui/icons-material";
import "./accountreconciliationform.scss";
import { useSelector } from "react-redux";
import { useContext, useState } from "react";
import { CircularProgress } from "@mui/material";
import AccountsContext from "../../../context/AccountsContext";
import { AuthContext } from "../../../context/AuthContext";
import TransactionContext from "../../../context/transactionContext";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase";
import { motion } from "framer-motion";

const AccountReconciliationForm = (props) => {
  const {
    setShowAccountsRecon,
    reconcileBalance,
    accountDetails,
    setShowAccountsFormModal,
  } = props;
  const { updateAccount } = useContext(AccountsContext);
  const { setData, setIncomeData, setOtherTransactions } =
    useContext(TransactionContext);
  const { currentUser, onDemo } = useContext(AuthContext);
  // -----------------------states--------------------------------------
  const currency = useSelector((state) => state.userSettings.currency);
  const adjustmentType = reconcileBalance > 0 ? "income" : "expense";
  const [dataReconInput, setDataReconInput] = useState({
    transactionType: adjustmentType === "expense" ? "Expense" : "Income",
    description: `${accountDetails.name} Account balance adjustment.`,
  });
  const [isLoading, setIsLoading] = useState(false);

  // -----------------------handlers--------------------------------------
  const handleCloseForm = (e) => {
    e.preventDefault();
    setShowAccountsRecon(false);
  };

  const handleInputRecon = (e) => {
    const id = e.target.id;
    let value = e.target.value;

    setDataReconInput({ ...dataReconInput, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // update account
    await updateAccount(
      accountDetails.id,
      accountDetails.name,
      accountDetails.group,
      accountDetails.beginningBalance,
      accountDetails.beginningBalanceDate,
      accountDetails.color
    );
    const amountValue = Math.round(Math.abs(reconcileBalance) * 100) / 100;

    // create transaction
    let userId = "";
    let transactionId = "";

    // convert datainput transactionType ["Expense", "Income", "adjustment"] to ["expense", "income", "transfer_to", "transfer_from"] for transactions schema
    let transactionType = "";
    if (dataReconInput.transactionType === "Expense") {
      transactionType = "expense";
    } else if (dataReconInput.transactionType === "Income") {
      transactionType = "income";
    } else if (dataReconInput.transactionType === "adjustment") {
      transactionType =
        adjustmentType === "expense" ? "transfer_to" : "transfer_from";
    }

    const newTransactionDetails = {
      date: Timestamp.fromDate(new Date()),
      amount: amountValue,
      category: "Others",
      vendor: "None",
      description: dataReconInput.description,
      type: transactionType,
      accountId: accountDetails.id,
    };

    // ---------- start: demo only -----------------------
    if (!currentUser && onDemo) {
      userId = "test";
      transactionId = new Date().valueOf().toString();

      const newTransactionObject = {
        ...newTransactionDetails,
        id: new Date().valueOf().toString(),
        userId: "test",
      };

      if (transactionType === "expense") {
        setData((prevState) =>
          [
            ...prevState,
            { ...newTransactionObject, type: transactionType },
          ].sort((item1, item2) => item2.date.seconds - item1.date.seconds)
        );
      } else if (transactionType === "income") {
        setIncomeData((prevState) =>
          [
            ...prevState,
            { ...newTransactionObject, type: transactionType },
          ].sort((item1, item2) => item2.date.seconds - item1.date.seconds)
        );
      } else {
        setOtherTransactions((prevState) =>
          [
            ...prevState,
            {
              ...newTransactionObject,
              category: "Adjustment",
              type: transactionType,
            },
          ].sort((item1, item2) => item2.date.seconds - item1.date.seconds)
        );
      }
    }
    // ---------- end: demo only -----------------------

    if (currentUser && !onDemo) {
      try {
        await addDoc(collection(db, "transactions"), {
          ...newTransactionDetails,
          userId: currentUser.uid,
        });
      } catch (err) {
        return;
      }

      // no need to update state since it's using snapshot listener
    }

    setShowAccountsFormModal((prevState) => ({
      ...prevState,
      show: false,
      mode: "add",
    }));
    setIsLoading(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
      }}
      exit={{
        opacity: 0,
        transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
      }}
      className="accountreconciliationform"
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          transition: {
            delay: 0.1,
            duration: 0.4,
            type: "spring",
            ease: [0.36, 0.66, 0.04, 1],
          },
        }}
        exit={{
          scale: 0.8,
          opacity: 0,
          transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
        }}
        className="formContainer"
      >
        <div className="closeContainer" onClick={handleCloseForm}>
          <Close className="closeIcon" />
        </div>
        <h1>Balance Adjustment</h1>
        <p>
          The difference of {currency.symbol}
          {reconcileBalance?.toLocaleString("en-us")} will be recorded to
          reconcile your ending balance. How would you like to record this
          amount?
        </p>
        <div className="inputsContainer">
          <form id="data-form-account-reconciliation" onSubmit={handleSubmit}>
            <div className="formInput">
              <label>Treat as</label>
              <select
                id="transactionType"
                name="transactionType"
                value={dataReconInput["transactionType"]}
                onChange={handleInputRecon}
                className="lightBorder"
                required
              >
                <option value={adjustmentType}>
                  {adjustmentType === "expense" ? "Expense" : "Income"}
                </option>
                <option value="adjustment">
                  Don't treat it as {adjustmentType}.
                </option>
              </select>
            </div>
            <div className="formInput">
              <label>Description</label>
              <textarea
                id="description"
                name="description"
                type="text"
                value={dataReconInput["description"]}
                onChange={handleInputRecon}
                style={{
                  minHeight: 32,
                  resize: "none",
                }}
                className="lightBorder"
              />
            </div>
            <div className="submitRow">
              <button className="btn-cancel" onClick={handleCloseForm}>
                Cancel
              </button>
              <button type="submit" className="submitButton btn-primary">
                {isLoading ? (
                  <CircularProgress size={"1.275rem"} className="spinner" />
                ) : (
                  <>
                    <span>Ok</span>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default AccountReconciliationForm;
