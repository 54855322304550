import { useContext, useEffect, useState } from "react";
import "./accounttransfers.scss";
import MobileContext from "../../context/MobileContext";
import AccountTransfersTable from "./components/AccountTransfersTable";
import TransactionContext from "../../context/transactionContext";
import { useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import AccountTransferForm from "../accounts/components/AccountTransferForm";
import moment from "moment";
import ConfirmDeleteModal from "../../components/deletemodal/ConfirmDeleteModal";
import { AuthContext } from "../../context/AuthContext";
import { doc, writeBatch } from "firebase/firestore";
import { db } from "../../firebase";
import AccountTransferMobile from "./components/AccountTransferMobile";
import { AddOutlined } from "@mui/icons-material";
import { convertArrayToObject } from "../../utils/utils";

const AccountTransfers = () => {
  const { isMobile } = useContext(MobileContext);
  const { currentUser, onDemo } = useContext(AuthContext);
  const { otherTransactions, setOtherTransactions } =
    useContext(TransactionContext);
  const accounts = useSelector((state) => state.accounts.userAccounts);
  const transferTransactions = getUniqueTransfers(otherTransactions);
  const [confirmDelete, setConfirmDelete] = useState({
    showMessage: false,
    id: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showAccountTransferModal, setShowAccountTransferModal] = useState({
    transferId: "",
    show: false,
    transferFromAccount: {},
    transferToAccount: {},
    date: moment(new Date()).format("YYYY-MM-DD"),
    mode: "empty",
    amount: 0,
    transferFromTransactionId: "",
    transferToTransactionId: "",
  });

  const accountsObj = convertArrayToObject(accounts);

  const handleEdit = (id) => {
    const transferTransaction = transferTransactions.find(
      (transaction) => transaction.id === id
    );
    const myDate = moment(
      new Date(transferTransaction.date.seconds * 1000)
    ).format("YYYY-MM-DD");
    const transferFromAccount = accountsObj[transferTransaction.accountFrom];
    const transferToAccount = accountsObj[transferTransaction.accountTo];
    const transactionAmount = transferTransaction.amount;
    setShowAccountTransferModal({
      transferId: id,
      show: true,
      transferFromAccount: transferFromAccount,
      transferToAccount: transferToAccount,
      date: myDate,
      mode: "edit",
      amount: transactionAmount,
      transferFromTransactionId: transferTransaction.transferFromTransactionId,
      transferToTransactionId: transferTransaction.transferToTransactionId,
    });
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    const transferTransaction = transferTransactions.find(
      (transaction) => transaction.id === id
    );
    const transactionTransferIds = [
      transferTransaction.transferFromTransactionId,
      transferTransaction.transferToTransactionId,
    ];
    console.log(transactionTransferIds);

    //demo mode only
    if (!currentUser && onDemo) {
      setOtherTransactions(
        otherTransactions.filter((item) => {
          //remove transfer transaction ids in the array
          return !transactionTransferIds.includes(item.id);
        })
      );
    }
    if (currentUser && !onDemo) {
      const batch = writeBatch(db);
      transactionTransferIds.forEach((transactionId) => {
        const docRef = doc(db, "transactions", transactionId);
        batch.delete(docRef);
      });
      try {
        await batch.commit();
      } catch (err) {
        console.log(err);
        return;
      }
    }

    setIsLoading(false);
    setConfirmDelete({
      showMessage: false,
      id: "",
    });
  };

  useEffect(() => {
    document.title = "Expense Aware: Account Transfers";
  }, []);

  return (
    <div className="accounttransfers">
      <div className="row">
        <h1 className="pageTitle">Account Transfers</h1>
      </div>
      <div className="bottomContent">
        {!isMobile && (
          <AccountTransfersTable
            accounts={accounts}
            transactionsData={transferTransactions}
            showAccountTransferModal={showAccountTransferModal}
            setShowAccountTransferModal={setShowAccountTransferModal}
            handleEdit={handleEdit}
            setConfirmDelete={setConfirmDelete}
          />
        )}
      </div>
      {isMobile && (
        <AccountTransferMobile
          transferTransactions={transferTransactions}
          handleEdit={handleEdit}
        />
      )}
      <AnimatePresence>
        {showAccountTransferModal.show && (
          <AccountTransferForm
            showAccountTransferModal={showAccountTransferModal}
            setShowAccountTransferModal={setShowAccountTransferModal}
            setConfirmDelete={setConfirmDelete}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {confirmDelete.showMessage && (
          <ConfirmDeleteModal
            handleDelete={handleDelete}
            confirmDelete={confirmDelete}
            setConfirmDelete={setConfirmDelete}
            title={"Delete Transfer Record"}
            description={""}
            isLoading={isLoading}
            note={""}
          />
        )}
      </AnimatePresence>
      <div
        className="add-toggle"
        onClick={() =>
          setShowAccountTransferModal({
            show: true,
            transferFromAccount: {},
            mode: "empty",
          })
        }
      >
        <AddOutlined className="icon" />
      </div>
    </div>
  );
};

export default AccountTransfers;

function getUniqueTransfers(transactions) {
  let uniqueTransfers = [];
  transactions.reduce((obj, transaction) => {
    const transferId = transaction.transferId;
    //check if document has transferId field
    if (transferId) {
      //create an object for unique transfer_id
      if (!obj[transferId]) {
        obj[transferId] = {
          id: transferId,
          date: transaction.date,
          accountFrom: "",
          accountTo: "",
          amount: transaction.amount,
          transferFromTransactionId: "",
          transferToTransactionId: "",
        };
        uniqueTransfers.push(obj[transferId]);
      }

      // different logic in submitting backend
      // type:transfer_to = deduction in account(sender)
      // type:transfer_from = add in account(recipient)
      if (transaction.type === "transfer_to") {
        obj[transferId].accountFrom = transaction.accountId;
        obj[transferId].transferFromTransactionId = transaction.id;
      }
      if (transaction.type === "transfer_from") {
        obj[transferId].accountTo = transaction.accountId;
        obj[transferId].transferToTransactionId = transaction.id;
      }
    }

    return obj;
  }, {});

  return uniqueTransfers;
}
