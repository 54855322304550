import { CircularProgress } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";

const SubmitButton = (props) => {
  const { isSubmitting, isEditing, type } = props;

  return (
    <>
      <button
        className={`buttonContainer ${
          type === "expense" ? "expense" : "income"
        }`}
        type="submit"
        form="data-form"
      >
        {isSubmitting ? (
          <CircularProgress size={"1.275rem"} className="spinner" />
        ) : (
          <>
            {!isEditing && <AddOutlined className="icon" />}
            <span>{isEditing ? "Save" : "Add New"}</span>
          </>
        )}
      </button>
    </>
  );
};

export default SubmitButton;
