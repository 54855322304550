import React, { Suspense, useState } from "react";
import LazyLoading from "../../../components/lazyloading/LazyLoading";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { shadeColor } from "../../../utils/utils";
import { AddOutlined } from "@mui/icons-material";

const AccountTransfersTable = (props) => {
  const {
    accounts,
    transactionsData,
    handleEdit,
    showAccountTransferModal,
    setShowAccountTransferModal,
    setConfirmDelete,
  } = props;
  const isDarkMode = useSelector((state) => state.userSettings.isDarkMode);
  const currency = useSelector((state) => state.userSettings.currency);

  const tableColumns = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 105,
      align: "center",
      headerAlign: "center",
      type: "date",
      valueGetter: (params) => {
        const { seconds } = params.value;
        const date = new Date(seconds * 1000);
        return date;
      },
      valueFormatter: (params) => {
        return params.value?.toLocaleDateString(undefined, { timeZone: "UTC" });
      },
    },
    {
      field: "accountFrom",
      headerName: "Account sender",
      type: "singleSelect",
      valueOptions: accounts.map((item) => item.name),
      flex: 2,
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const account = accounts.find(
          (item) => item.id === params.row.accountFrom
        );
        return (
          <div
            className={"cellWithColor"}
            style={{
              color: account?.color
                ? shadeColor(account?.color, isDarkMode ? 20 : -20)
                : "",
              backgroundColor: account?.color + "1A",
              border: `1px solid ${account?.color}33`,
            }}
          >
            {account ? account.name : "Inactive Account"}
          </div>
        );
      },
      valueGetter: (params) => {
        const accountName =
          accounts.find((item) => item.id === params.row.accountFrom)?.name ||
          "";
        return accountName;
      },
    },
    {
      field: "accountTo",
      headerName: "Account recipient",
      type: "singleSelect",
      valueOptions: accounts.map((item) => item.name),
      flex: 2,
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const account = accounts.find(
          (item) => item.id === params.row.accountTo
        );
        return (
          <div
            className={"cellWithColor"}
            style={{
              color: account?.color
                ? shadeColor(account?.color, isDarkMode ? 20 : -20)
                : "",
              backgroundColor: account?.color + "1A",
              border: `1px solid ${account?.color}33`,
            }}
          >
            {account ? account.name : "Inactive Account"}
          </div>
        );
      },
      valueGetter: (params) => {
        const accountName =
          accounts.find((item) => item.id === params.row.accountTo)?.name || "";
        return accountName;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      flex: 2,
      minWidth: 100,
      headerAlign: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const valueFormatted = Number(params.value).toLocaleString("en-US");
        return `${currency.symbol} ${valueFormatted}`;
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      minWidth: 110,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="editButton"
              onClick={() => {
                handleEdit(params.row.id);
              }}
            >
              Edit
            </div>
            <div
              className="deleteButton"
              onClick={() => {
                setConfirmDelete({ showMessage: true, id: params.row.id });
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="datatableTitle">
        Transfer Records
        <div className="topButtons">
          <button
            className="add btn-primary"
            onClick={() =>
              setShowAccountTransferModal({
                show: true,
                transferFromAccount: {},
                mode: "empty",
              })
            }
          >
            <>
              <AddOutlined className="icon" />
              <span>Add New</span>
            </>
          </button>
        </div>
      </div>
      <div className="datagridContainer">
        <Suspense fallback={<LazyLoading />}>
          <DataGrid
            className="datagrid"
            rows={transactionsData}
            columns={tableColumns}
            sx={{
              "& .MuiDataGrid-row:hover": {
                backgroundColor: isDarkMode ? "#2d2d2d" : "#e1e5ee",
              },
            }}
          />
        </Suspense>
      </div>
    </>
  );
};

export default AccountTransfersTable;
