import { useSelector } from "react-redux";
import "./form.scss";
import { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import NewBadge from "../newbadge/NewBadge";
import { getActualTotalFromArray, months } from "../../utils/utils";
import BudgetContext from "../../context/BudgetContext";
import TransactionContext from "../../context/transactionContext";
import { AnimatePresence, motion } from "framer-motion";
import UserProjectsContext from "../../context/UserProjectsContext";
import { useRef } from "react";
import { useEffect } from "react";

const Form = (props) => {
  const {
    handleSubmit,
    date,
    onChangeDate,
    handleInput,
    categories,
    dataInput,
    type,
    isModal,
    sheetId,
    setDataInput,
  } = props;
  const currency = useSelector((state) => state.userSettings.currency);
  const accounts = useSelector((state) => state.accounts.userAccounts);
  const { projects } = useContext(UserProjectsContext);
  const { currentUser, onDemo } = useContext(AuthContext);
  const { data } = useContext(TransactionContext);
  const { budgetDataAll } = useContext(BudgetContext);
  const [showProjectsList, setShowProjectsList] = useState(false);
  const [projectSelected, setProjectSelected] = useState({
    id: "",
    name: "",
  });
  const dropdownRef = useRef();

  const handleSelectProject = (id, name) => {
    setProjectSelected({
      id: id,
      name: name,
    });

    // if user did not select: "No project."
    setDataInput({
      ...dataInput,
      sheetId: id,
    });
    setShowProjectsList(false);
  };

  const remainingBudget = useMemo(() => {
    if (type === "expense" && dataInput.category) {
      const currentDateSelected = new Date(date);
      const month = months[currentDateSelected.getMonth()];
      const year = currentDateSelected.getFullYear();

      const categoryActual = data.filter(
        (item) =>
          item.category?.toUpperCase() === dataInput.category?.toUpperCase()
      );
      const actualTotal = getActualTotalFromArray(
        categoryActual,
        month,
        year,
        "monthly"
      );

      const categoryBudget = budgetDataAll.find((item) => {
        return (
          item.category?.toUpperCase() === dataInput.category?.toUpperCase() &&
          item.year === year.toString()
        );
      });

      if (categoryBudget) {
        const budgetTotal = categoryBudget?.amount[month];
        return Math.max(budgetTotal - actualTotal, 0);
      } else {
        return 0;
      }
    }
  }, [
    new Date(date).getMonth(),
    new Date(date).getFullYear(),
    dataInput.category,
  ]);

  useEffect(() => {
    let projectId = undefined;

    if (sheetId) {
      projectId = sheetId;
    } else if (dataInput.sheetId) {
      projectId = dataInput.sheetId;
    }
    const project = projects.find((project) => project.id === projectId);

    if (project) {
      setProjectSelected({
        id: project.id,
        name: project.name,
      });
    }
  }, [sheetId]);

  useEffect(() => {
    const handler = (e) => {
      if (!dropdownRef?.current?.contains(e.target)) {
        setShowProjectsList(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [dropdownRef]);

  return (
    <div className="form">
      <div className="inputsContainer">
        {type === "expense" && (
          <div className="row projectAddRow">
            <button
              className={`projectAdd ${projectSelected.name ? "active" : ""}`}
              onClick={() => setShowProjectsList(true)}
            >
              {projectSelected.name || "Add to Project"}
            </button>
            <AnimatePresence>
              {showProjectsList && (
                <motion.div
                  initial={{ opacity: 0, y: "-20%" }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: {
                      duration: 0.4,
                      ease: [0.36, 0.66, 0.04, 1],
                    },
                  }}
                  exit={{
                    opacity: 0,
                    y: "-20%",
                    transition: {
                      duration: 0.3,
                      ease: [0.36, 0.66, 0.04, 1],
                    },
                  }}
                  className="projectsListContainer"
                  ref={dropdownRef}
                >
                  {projects.map((project) => (
                    <div
                      className="projectItem"
                      key={project.id}
                      onClick={() =>
                        handleSelectProject(project.id, project.name)
                      }
                    >
                      {project.name}
                    </div>
                  ))}
                  <div
                    className="projectItem noProject"
                    onClick={() => handleSelectProject("", "")}
                  >
                    No Project.
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
        <form id="data-form" onSubmit={handleSubmit}>
          <div className="formInput dateInput">
            <label>Date</label>
            <input
              id="date"
              name="date"
              type="date"
              value={date}
              onChange={onChangeDate}
            />
          </div>
          <div className="formInput">
            <label>
              Account
              <Link
                to={`${
                  !currentUser && onDemo ? "/demo" : "/my-dashboard"
                }/my-accounts`}
                className="link"
              >
                Edit
              </Link>
            </label>
            <select
              id="accountId"
              name="accountId"
              value={dataInput["accountId"]}
              onChange={handleInput}
              required
            >
              <option disabled value="">
                {" "}
                -- select account --{" "}
              </option>
              {accounts.map((account) => {
                return (
                  <option value={account.id} key={account.id}>
                    {account.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="formInput">
            <label>
              Category
              <Link
                to={`${
                  !currentUser && onDemo ? "/demo" : "/my-dashboard"
                }/categories`}
                className="link"
              >
                Edit
              </Link>
            </label>
            <select
              id="category"
              name="category"
              value={dataInput["category"]}
              onChange={handleInput}
              required
            >
              <option disabled value="">
                {" "}
                -- select category --{" "}
              </option>
              {categories.map(
                (category, index) =>
                  !category.hidden && (
                    <option
                      key={`${category.label}.${index}`}
                      value={category.label}
                    >
                      {`${category.icon} ${category.label}`}
                    </option>
                  )
              )}
            </select>
          </div>
          {type === "expense" && isModal && (
            <div className="remainingBudget">
              <div className="label">Budget left:</div>
              <div className="currency">{currency.symbol}</div>
              <div className="amount">{remainingBudget?.toLocaleString()}</div>
            </div>
          )}
          <div className="formInput">
            <label>{type === "expense" ? "Vendor" : "Source"}</label>
            <input
              id="vendor"
              name="vendor"
              type="text"
              value={dataInput["vendor"]}
              onChange={handleInput}
            />
          </div>
          <div className="formInput">
            <label>Description</label>
            <input
              id="description"
              name="description"
              type="text"
              value={dataInput["description"]}
              onChange={handleInput}
            />
          </div>
          <div className="formInput">
            <label>Amount</label>
            <div
              className="amountField"
              style={{ "--currency-var": `"${currency.symbol}"` }}
            >
              <input
                id="amount"
                name="amount"
                type="text"
                inputMode="decimal"
                pattern="^[+-]?([0-9]{1,3}(,[0-9]{3})*(\.[0-9]+)?|\d*\.\d+|\d+)$"
                value={dataInput["amount"]}
                onChange={handleInput}
                required
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
