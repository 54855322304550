import { useSelector } from "react-redux"
import "./summarywidget.scss"

const SummaryWidget = (props) => {
    const {title, amount, label, selectedFrequency} = props
    const currency = useSelector((state) => state.userSettings.currency)

  return (
    <div className={`summarywidget ${selectedFrequency ? "active" : ""}`}>
      <div className="summaryContainer">
          <div className="widgetTitle">{title}</div>
          <div className="row">
              <div className="amount">
                  <span className="currency">{currency.symbol}</span>
                  {amount.toLocaleString("en-us")}
              </div>
              <div className="frequency">{label}</div>
          </div>
      </div>
    </div>
  )
}

export default SummaryWidget