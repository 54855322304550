import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { MobileContextProvider } from './context/MobileContext';
import { AuthContextProvider } from './context/AuthContext';
import {store} from "./redux/store"
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/errorboundary/ErrorFallback';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<ErrorFallback/>}>
      <Provider store={store}>
        <AuthContextProvider>
          <MobileContextProvider>
            <App />
          </MobileContextProvider>
        </AuthContextProvider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
