import { Close, ContentCopy } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { collection, doc, setDoc } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import BudgetContext from "../../context/BudgetContext";
import UserSettingsContext from "../../context/UserSettingsContext";
import { db } from "../../firebase";
import "./budgetform.scss";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { useLayoutEffect } from "react";
import { motion } from "framer-motion";

const BudgetForm = (props) => {
  const initialState = {
    january: "",
    february: "",
    march: "",
    april: "",
    may: "",
    june: "",
    july: "",
    august: "",
    september: "",
    october: "",
    november: "",
    december: "",
  };
  const { handleCloseBudgetForm, editData, selectedCategory, title } = props;
  const { year } = useSelector((state) => state.userSettings.options);
  const currency = useSelector((state) => state.userSettings.currency);
  const [dataInput, setDataInput] = useState(initialState);
  const [applyToAllMonthsInput, setApplyToAllMonthsInput] = useState("");
  const [notesInput, setNotestInput] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setBudgetData, setBudgetDataAll } = useContext(BudgetContext);
  const { currentUser, onDemo } = useContext(AuthContext);
  const { months } = useContext(UserSettingsContext);

  const textareaRef = useRef(null);

  const handleInput = (e) => {
    const id = e.target.id; //month
    let value = e.target.value;

    const num = value.replace(/[^0-9$.,]/g, "").replace(/,/gi, "");
    const parts = num.split("."); //split whole number and decimal
    parts[0] = parts[0].split(/(?=(?:\d{3})+$)/).join(","); // split whole number with commas
    value = parts.join(".");

    setDataInput({ ...dataInput, [id]: value });

    const total = Object.keys(dataInput).reduce((acc, key) => {
      let amountValue = parseFloat(dataInput[key].replace(/,/gi, ""));
      if (amountValue) {
        return (acc += Math.round(amountValue * 100) / 100);
      }
      return (acc += 0);
    }, 0);
    setTotalAmount(total.toLocaleString("en-US"));
  };

  const handleApplyAllMonthsInput = (e) => {
    let value = e.target.value;

    const num = value.replace(/[^0-9$.,]/g, "").replace(/,/gi, "");
    const parts = num.split("."); //split whole number and decimal
    parts[0] = parts[0].split(/(?=(?:\d{3})+$)/).join(","); // split whole number with commas
    value = parts.join(".");

    setApplyToAllMonthsInput(value);
  };

  const handleApplyToAllMonths = (e) => {
    e.preventDefault();

    setDataInput({
      january: applyToAllMonthsInput,
      february: applyToAllMonthsInput,
      march: applyToAllMonthsInput,
      april: applyToAllMonthsInput,
      may: applyToAllMonthsInput,
      june: applyToAllMonthsInput,
      july: applyToAllMonthsInput,
      august: applyToAllMonthsInput,
      september: applyToAllMonthsInput,
      october: applyToAllMonthsInput,
      november: applyToAllMonthsInput,
      december: applyToAllMonthsInput,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let sumAmount = 0;
    //replace inputs from string to float: {january:300, february:0}
    const amountToSubmit = Object.keys(dataInput).reduce((obj, key) => {
      const amountValue = parseFloat(dataInput[key].replace(/,/gi, ""));
      //if amount = ""
      if (isNaN(amountValue)) {
        obj[key] = 0; //{january:0}
      } else {
        obj[key] = amountValue; //{january:1001}
        sumAmount += amountValue;
      }
      return obj;
    }, {});

    // ------------------ demo mode only -------------------------------------
    if (!currentUser && onDemo) {
      if (editData) {
        const updatedDoc = {
          userId: "user",
          category: editData.category,
          year: editData.year,
          amount: amountToSubmit,
          notes: notesInput,
        };
        const newObject = {
          ...updatedDoc,
          id: new Date().valueOf().toString(),
          totalAmount: sumAmount,
        };
        setBudgetData((prevState) => {
          const newState = prevState.map((obj) => {
            let oldObject = { ...obj };
            if (obj.id === editData.id) {
              return newObject;
            } else {
              return oldObject;
            }
          });
          return newState;
        });

        setBudgetDataAll((prevState) => {
          const newState = prevState.map((obj) => {
            let oldObject = { ...obj };
            if (obj.id === editData.id) {
              return newObject;
            } else {
              return oldObject;
            }
          });
          return newState;
        });
      } else {
        const newDoc = {
          userId: "user",
          category: selectedCategory,
          year: year,
          amount: amountToSubmit,
          notes: notesInput,
        };
        const newObject = {
          ...newDoc,
          id: new Date().valueOf().toString(),
          totalAmount: sumAmount,
        };
        setBudgetData((prevState) => [...prevState, newObject]);
        setBudgetDataAll((prevState) => [...prevState, newObject]);
      }
      setDataInput(initialState);
      setIsSubmitting(false);
      handleCloseBudgetForm();
      return; // exit function for demo mode
    } //--------------------- demo mode only -----------------

    //submit data

    //with existing document
    if (editData) {
      try {
        const updatedDoc = {
          userId: editData.userId,
          category: editData.category,
          year: editData.year,
          amount: amountToSubmit,
          notes: notesInput,
        }; // document to be submitted
        await setDoc(doc(db, "budget", editData.id), updatedDoc);

        const newObject = {
          ...updatedDoc,
          id: editData.id,
          totalAmount: sumAmount,
        };
        setBudgetData((prevState) => {
          const newState = prevState.map((obj) => {
            let oldObject = { ...obj };
            if (obj.id === editData.id) {
              return newObject;
            } else {
              return oldObject;
            }
          });
          return newState;
        });
        setBudgetDataAll((prevState) => {
          const newState = prevState.map((obj) => {
            let oldObject = { ...obj };
            if (obj.id === editData.id) {
              return newObject;
            } else {
              return oldObject;
            }
          });
          return newState;
        });

        setDataInput(initialState);
        setIsSubmitting(false);
        handleCloseBudgetForm();
      } catch (err) {
        console.log(err);
      }

      // new document
    } else {
      try {
        const newDoc = {
          userId: currentUser.uid,
          category: selectedCategory,
          year: year,
          amount: amountToSubmit,
          notes: notesInput,
        }; // document to be submitted
        const newDocRef = doc(collection(db, "budget")); // to get Id when submitted and used to update budgetData
        await setDoc(newDocRef, newDoc);

        const newDocId = newDocRef.id;
        const newObject = { ...newDoc, id: newDocId, totalAmount: sumAmount };
        setBudgetData((prevState) => [...prevState, newObject]);
        setBudgetDataAll((prevState) => [...prevState, newObject]);

        setDataInput(initialState);
        setIsSubmitting(false);
        handleCloseBudgetForm();
      } catch (err) {
        console.log(err);
      }
    }
  };

  // custom html component
  const FormInput = (month) => {
    return (
      <div className="formInput" key={month}>
        <label>{month}</label>
        <div
          className="amountField"
          style={{ "--currency-var": `"${currency.symbol}"` }}
        >
          <input
            id={month}
            name={month}
            type="text"
            inputMode="decimal"
            pattern="^[+-]?([0-9]{1,3}(,[0-9]{3})*(\.[0-9]+)?|\d*\.\d+|\d+)$"
            value={dataInput[month]}
            onChange={handleInput}
            className="lightBorder"
          />
        </div>
      </div>
    );
  };

  // dynamic height for textarea
  useLayoutEffect(() => {
    let timeOut = setTimeout(() => {
      //reset height - important to shrink on delete
      textareaRef.current.style.height = "inherit";
      //set height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }, 150);
    return () => clearTimeout(timeOut);
  }, [notesInput]);

  // calculate total amount per input(months)
  useEffect(() => {
    const total = Object.keys(dataInput).reduce((acc, key) => {
      let amountValue = parseFloat(dataInput[key].replace(/,/gi, ""));
      if (amountValue) {
        return (acc += Math.round(amountValue * 100) / 100);
      }
      return (acc += 0);
    }, 0);
    setTotalAmount(total.toLocaleString("en-US"));
  }, [dataInput]);

  // put existing data to the form/input
  useEffect(() => {
    // if selected category has data
    if (editData) {
      // if data has an amount collection
      if (editData.amount) {
        const myDataToString = Object.entries(editData.amount).reduce(
          (obj, [key, value]) => {
            obj[key] = value.toLocaleString("en-US");
            return obj;
          },
          {}
        );
        setDataInput((prevState) => ({ ...prevState, ...myDataToString }));
      }
      if (editData.notes) {
        setNotestInput(editData.notes);
      }
    }
  }, []);

  // lock scrolling
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
      }}
      exit={{
        opacity: 0,
        transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
      }}
      className="budgetform"
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          transition: {
            delay: 0.1,
            duration: 0.4,
            type: "spring",
            ease: [0.36, 0.66, 0.04, 1],
          },
        }}
        exit={{
          scale: 0.8,
          opacity: 0,
          transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
        }}
        className="formContainer"
      >
        <div className="closeContainer" onClick={handleCloseBudgetForm}>
          <Close className="closeIcon" />
        </div>
        <h1>{title}</h1>
        <div className="row">
          <div className="applyToAllMonths">
            <div
              className="amountField"
              style={{ "--currency-var": `"${currency.symbol}"` }}
            >
              <input
                id={"applyToAllMonths"}
                name={"applyToAllMonths"}
                type="text"
                inputMode="decimal"
                pattern="^[+-]?([0-9]{1,3}(,[0-9]{3})*(\.[0-9]+)?|\d*\.\d+|\d+)$"
                value={applyToAllMonthsInput}
                onChange={handleApplyAllMonthsInput}
                className="lightBorder"
              />
            </div>
            <button
              onClick={handleApplyToAllMonths}
              className="applyToAllMonthsButton"
            >
              Apply to all
              <ContentCopy className="icon" />
            </button>
          </div>
          <div className="totalCounter">
            <div>Total:</div>
            <span className="currency">{currency.symbol}</span>
            <span className="totalAmount">{totalAmount}</span>
          </div>
        </div>
        <div className="form">
          <form id="budget-form" onSubmit={handleSubmit}>
            <div className="monthsGroupInput">
              {months.map((month) => FormInput(month))}
            </div>
            <div className="notesInput">
              <label>Notes</label>
              <textarea
                ref={textareaRef}
                onChange={(e) => setNotestInput((prevState) => e.target.value)}
                value={notesInput}
                style={{
                  minHeight: 32,
                  resize: "none",
                }}
                className="lightBorder"
              />
            </div>
          </form>
        </div>
        <div className="buttonWrapper">
          <button className="buttonContainer" type="submit" form="budget-form">
            {isSubmitting ? (
              <CircularProgress size={"1.275rem"} className="spinner" />
            ) : (
              <>
                <span>Save</span>
              </>
            )}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default BudgetForm;
