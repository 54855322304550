import { useEffect } from "react"
import "./pagenotfound.scss"
import { Link } from 'react-router-dom'
import iconLogo from "../../images/iconLogo.webp"

const PageNotFound = () => {
  useEffect(()=>{
    document.title = "Expense Aware | Page not found"
  },[])
  return (
    <div className='pagenotfound'>
      <div className="logoContainer">
            <img src={iconLogo} alt="" className='logoImage'/>
        </div>
      <h1>404</h1>
      <h2>The page you are looking for doesn't exist.</h2>
      <Link to={"/"}>
        <button className="backToHome">Back to Home</button>
      </Link>
    </div>
  )
}

export default PageNotFound