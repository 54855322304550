import { useContext } from "react";
import "./accountcard.scss";
import { useSelector } from "react-redux";
import {
  DeleteOutlineOutlined,
  EditOutlined,
  SwapHorizOutlined,
} from "@mui/icons-material";
import { useState } from "react";
import ConfirmDeleteModal from "../../../components/deletemodal/ConfirmDeleteModal";
import AccountTransferForm from "./AccountTransferForm";
import AccountsContext from "../../../context/AccountsContext";
import Tooltip from "../../../components/tooltip/Tooltip";
import { AnimatePresence, motion } from "framer-motion";

const AccountCard = (props) => {
  const {
    userAccount,
    userAccountTransactions,
    activeAccount,
    handleClickAccount,
    editingAccounts,
    setShowAccountsFormModal,
  } = props;

  // ------------------ Contexts ---------------------------------------
  const { deleteAccount } = useContext(AccountsContext);
  // ------------------ States ---------------------------------------
  const currency = useSelector((state) => state.userSettings.currency);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState({
    showMessage: false,
    id: "",
    accountName: "",
  });
  const [showAccountTransferModal, setShowAccountTransferModal] = useState({
    show: false,
    transferFromAccount: {},
  });
  // ------------------ fixed states ---------------------------------------
  const endingBalance = calculateEndingBalance(userAccountTransactions);
  const [wholeNumber, cent] = endingBalance?.toLocaleString("en-us").split(".");

  // ------------------ Handlers ---------------------------------------
  const handleEditAccount = () => {
    setShowAccountsFormModal((prevState) => ({
      ...prevState,
      show: true,
      mode: "edit",
      editAccount: { ...userAccount, endingBalance: endingBalance },
    }));
  };

  const handleTransferAccountBalance = () => {
    setShowAccountTransferModal((prevState) => ({
      ...prevState,
      show: true,
      transferFromAccount: userAccount,
    }));
  };

  const handleDeleteAccount = async (id) => {
    setIsLoading(true);
    await deleteAccount(id);
    setIsLoading(false);
    setConfirmDelete((prevState) => ({
      showMessage: false,
      id: "",
      accountName: "",
    }));
  };

  return (
    <div
      className={`accountcard ${
        activeAccount === userAccount.id ? "active" : ""
      } ${editingAccounts ? "disabled" : ""}`}
      onClick={() => handleClickAccount(userAccount.id)}
    >
      <div
        className="topCard"
        style={{ backgroundColor: userAccount?.color + "E6" }}
      >
        <div className="accountName">{userAccount?.name}</div>
      </div>
      <div className="bottomCard">
        <div className="accountBalance">
          <span className="currency">{currency.symbol}</span>
          <span className="amount">{wholeNumber}</span>
          {cent && <span className="cent">.{cent}</span>}
        </div>
      </div>
      <AnimatePresence>
        {editingAccounts && (
          <motion.div
            initial={{ y: "-20%", opacity: 0 }}
            animate={{
              y: 0,
              opacity: 1,
              transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
            }}
            exit={{
              y: "-20%",
              opacity: 0,
              transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
            }}
            className="accountActions"
          >
            <button className="actionContainer">
              <Tooltip text={"Transfer"}>
                <SwapHorizOutlined
                  className="icon"
                  onClick={handleTransferAccountBalance}
                />
              </Tooltip>
            </button>
            <button className="actionContainer" onClick={handleEditAccount}>
              <Tooltip text={"Edit"}>
                <EditOutlined className="icon" />
              </Tooltip>
            </button>
            <button
              className="actionContainer"
              onClick={() =>
                setConfirmDelete((prevState) => ({
                  ...prevState,
                  id: userAccount.id,
                  showMessage: true,
                  accountName: userAccount.name,
                }))
              }
            >
              <Tooltip text={"Delete"}>
                <DeleteOutlineOutlined className="icon" />
              </Tooltip>
            </button>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showAccountTransferModal.show && (
          <AccountTransferForm
            showAccountTransferModal={showAccountTransferModal}
            setShowAccountTransferModal={setShowAccountTransferModal}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {confirmDelete.showMessage && (
          <ConfirmDeleteModal
            handleDelete={handleDeleteAccount}
            confirmDelete={confirmDelete}
            setConfirmDelete={setConfirmDelete}
            title={"Are you sure you want to delete this account?"}
            note={
              "Please note that deleting an account will remove the account itself from your dashboard, but the records associated with it will still be included in your expense and income records."
            }
            description={confirmDelete.accountName}
            isLoading={isLoading}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default AccountCard;

const calculateEndingBalance = (transactions) => {
  if (transactions) {
    const totalBalance = Object.keys(transactions)?.reduce(
      (totalAmount, key) => {
        const amount = transactions[key]?.amount;
        return (totalAmount += amount);
      },
      0
    );
    return totalBalance;
  }

  return 0;
};
