import "./categorytotals.scss";
import CategoryIcon from "../../components/icons/CategoryIcon";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import EmojiIcon from "../../components/icons/EmojiIcon";

const CategoryTotals = (props) => {
  const userCategories = useSelector(
    (state) => state.userSettings.userCategories
  );
  const userIncomeCategories = useSelector(
    (state) => state.userSettings.userIncomeCategories
  );
  const currency = useSelector((state) => state.userSettings.currency);
  const { categoryAmountTotals, showCategory, type } = props;

  return (
    <div className={`categorytotals ${showCategory ? "expanded" : ""}`}>
      <AnimatePresence>
        {(type === "income" ? [...userIncomeCategories] : [...userCategories])
          .sort((category1, category2) => {
            const category1Amount =
              categoryAmountTotals?.[category1.label] || 0;
            const category2Amount =
              categoryAmountTotals?.[category2.label] || 0;
            return category2Amount - category1Amount;
          })
          .map((category) => {
            const categoryAmount =
              categoryAmountTotals?.[category.label]?.toLocaleString("en-US");

            return (
              (!category.hidden ||
                categoryAmountTotals?.[category.label] !== undefined) && (
                <motion.div
                  layout
                  className="categoryContainer"
                  key={category.label}
                >
                  <div className="categoryTitle">
                    <div className="categoryIcon">
                      {category && <EmojiIcon emoji={category.icon} size="" />}
                    </div>
                    <div className="categoryName">{category.label}</div>
                  </div>
                  <div className="categoryAmount">
                    <div className="amount">
                      {categoryAmount ? (
                        <>
                          <span className="currency">{currency.symbol}</span>
                          {categoryAmount}
                        </>
                      ) : (
                        <>-</>
                      )}
                    </div>
                  </div>
                </motion.div>
              )
            );
          })}
      </AnimatePresence>
    </div>
  );
};

export default CategoryTotals;
