import {
  AddOutlined,
  CheckOutlined,
  LockOutlined,
  NotInterestedOutlined,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import {
  collection,
  deleteDoc,
  doc,
  setDoc,
  Timestamp,
  writeBatch,
} from "firebase/firestore";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import SubscriptionContext from "../../context/SubscriptionContext";
import { db } from "../../firebase";
import "./subscription.scss";
import SubscriptionWidget from "./SubscriptionWidget";
import SummaryWidget from "./SummaryWidget";
import TransactionContext from "../../context/transactionContext";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { getNextBillingDate, getPreviousBillingDate } from "../../utils/utils";

const Subscription = () => {
  //-------------------- useContexts -----------------------------
  const { subscriptionData, setSubscriptionData } =
    useContext(SubscriptionContext);
  const { currentUser, currentUserInfo, onDemo } = useContext(AuthContext);
  const { setData } = useContext(TransactionContext);
  // ------------------- useSelector-------------------------------------
  const userCategories = useSelector(
    (state) => state.userSettings.userCategories
  );
  const accounts = useSelector((state) => state.accounts.userAccounts);
  // ------------------- useStates-------------------------------------
  const [summarySubscription, setSummarySubscription] = useState({
    weeklyTotal: 0,
    monthlyTotal: 0,
    quarterlyTotal: 0,
    annualTotal: 0,
  });
  const initialState = {
    name: "",
    imageUrl: "",
    vendor: "",
    description: "",
    accountId: "",
    category: "",
    paymentMethod: "",
    cost: "",
    billingCycle: "",
    frequency: "",
  };
  const [dataInput, setDataInput] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [creatingNewSubscription, setCreatingNewSubscription] = useState(false);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [confirmDelete, setConfirmDelete] = useState({
    showMessage: false,
    id: "",
    name: "",
  });
  const [selectedFrequency, setSelectedFrequency] = useState("");

  const paymentFrequencies = ["Weekly", "Monthly", "Quarterly", "Annual"];
  const [subscriptionList, setSubscriptionList] = useState([]);
  //------------------------ useEffect -------------------------------------

  // calculates cost per period of current subscriptions
  useEffect(() => {
    const summary = subscriptionData.reduce(
      (obj, item) => {
        switch (item.frequency) {
          case "Weekly":
            obj.weeklyTotal += item.cost;
            obj.monthlyTotal += item.cost * 4;
            obj.quarterlyTotal += item.cost * 13;
            obj.annualTotal += item.cost * 52;
            break;
          case "Monthly":
            obj.monthlyTotal += item.cost;
            obj.quarterlyTotal += item.cost * 3;
            obj.annualTotal += item.cost * 12;
            break;
          case "Quarterly":
            obj.quarterlyTotal += item.cost;
            obj.annualTotal += item.cost * 4;
            break;
          case "Annual":
            obj.annualTotal += item.cost;
            break;
          default:
            break;
        } // end switch
        return obj;
      },
      { weeklyTotal: 0, monthlyTotal: 0, quarterlyTotal: 0, annualTotal: 0 }
    );
    setSummarySubscription(summary);
    setSubscriptionList(subscriptionData);
  }, [subscriptionData]); // end of useEffect
  // filter subscriptions list based on selected frequency
  useEffect(() => {
    if (selectedFrequency) {
      setSubscriptionList(
        subscriptionData.filter((item) => item.frequency === selectedFrequency)
      );
    } else {
      setSubscriptionList(subscriptionData);
    }
  }, [subscriptionData, selectedFrequency]);
  //------------------------ handlers -------------------------------------
  const handleInput = (e) => {
    const id = e.target.id;
    let value = e.target.value;
    if (id === "cost") {
      const num = value.replace(/[^0-9$.,]/g, "").replace(/,/gi, "");
      const parts = num.split("."); //split whole number and decimal
      parts[0] = parts[0].split(/(?=(?:\d{3})+$)/).join(","); // split whole number with commas
      value = parts.join(".");
      // value = num.split(/(?=(?:\d{3})+$)/).join(",")
    }

    setDataInput({ ...dataInput, [id]: value });
  }; // end handleInput
  // creating new subscription doc and expense records
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    //creating Payment Records
    let billingDate = new Date(new Date(date).setHours(0, 0, 0, 0));
    const billingDay = new Date(date).getDate();
    let expenseRecord = {
      accountId: "",
      category: "",
      vendor: "",
      description: "",
      amount: "",
      date: "",
      userId: currentUser ? currentUser.uid : "test",
      type: "expense",
    };
    let amountValue = parseFloat(dataInput.cost.replace(/,/gi, ""));
    let totalCostIncurred = 0;
    let lastRecordDate = billingDate;
    let expenseRecordToSubmit = [];

    if (dataInput.billingCycle === "Start of Period") {
      while (billingDate <= new Date()) {
        totalCostIncurred += Math.round(amountValue * 100) / 100;

        expenseRecord = {
          ...expenseRecord,
          accountId: dataInput.accountId,
          category: dataInput.category,
          vendor: dataInput.vendor,
          description: `Recurring ${dataInput.name} - ${dataInput.description}`,
          amount: Math.round(amountValue * 100) / 100,
          date: Timestamp.fromDate(new Date(billingDate)),
        };
        lastRecordDate = billingDate;
        expenseRecordToSubmit.push(expenseRecord);

        billingDate = getNextBillingDate(
          billingDate,
          dataInput.frequency,
          billingDay
        );
      }

      if (lastRecordDate > new Date()) {
        lastRecordDate = getPreviousBillingDate(
          lastRecordDate,
          dataInput.frequency,
          billingDay
        );
      }
    } else if (dataInput.billingCycle === "End of Period") {
      while (billingDate <= new Date()) {
        billingDate = getNextBillingDate(
          billingDate,
          dataInput.frequency,
          billingDay
        );

        if (billingDate <= new Date()) {
          totalCostIncurred += Math.round(amountValue * 100) / 100;

          expenseRecord = {
            ...expenseRecord,
            accountId: dataInput.accountId,
            category: dataInput.category,
            vendor: dataInput.vendor,
            description: `Recurring ${dataInput.name} - ${dataInput.description}`,
            amount: Math.round(amountValue * 100) / 100,
            date: Timestamp.fromDate(
              new Date(
                billingDate.getFullYear(),
                billingDate.getMonth(),
                billingDate.getDate() - 1
              )
            ), // -1 not include the last day
          };

          lastRecordDate = new Date(
            billingDate.getFullYear(),
            billingDate.getMonth(),
            billingDate.getDate() - 1
          ); // -1 not include the last day
          expenseRecordToSubmit.push(expenseRecord);
        } //end if
      } // end of while loop
    } // end if else Billing Cycle

    //-------------------- demo mode only -------------------------------------
    if (!currentUser && onDemo) {
      const subscriptionRecordToSubmit = {
        ...dataInput,
        startDate: Timestamp.fromDate(new Date(date)),
        cost: Math.round(amountValue * 100) / 100,
        userId: "test",
        lastRecordDate: Timestamp.fromDate(lastRecordDate),
        totalCostIncurred: totalCostIncurred,
      };

      //create random ids for new expenseRecords
      let newDummyExpenseRecords = [];
      expenseRecordToSubmit.forEach((record) => {
        newDummyExpenseRecords.push({
          ...record,
          id: Math.random().toString(36),
        });
      });

      setData((prevState) =>
        [...prevState, ...newDummyExpenseRecords].sort(
          (item1, item2) => item2.date.seconds - item1.date.seconds
        )
      );
      setIsLoading(false);
      setCreatingNewSubscription(false);
      setSubscriptionData((prevState) => [
        ...prevState,
        {
          ...subscriptionRecordToSubmit,
          nextBillingDate: billingDate,
          id: Math.random().toString(36),
        },
      ]);
      setDate(moment().format("YYYY-MM-DD"));
      setDataInput(initialState);

      return; //exit function for demo mode
    } //-------------------- demo mode only -------------------------------------

    // -------------------Submit Data to Backend--------------------------------

    const subscriptionRecordToSubmit = {
      ...dataInput,
      startDate: Timestamp.fromDate(new Date(date)),
      cost: Math.round(amountValue * 100) / 100,
      userId: currentUser.uid,
      lastRecordDate: Timestamp.fromDate(lastRecordDate),
      totalCostIncurred: totalCostIncurred,
    };

    const batch = writeBatch(db);

    expenseRecordToSubmit.forEach((record) => {
      const docRef = doc(collection(db, "transactions"));
      batch.set(docRef, record);
    });
    try {
      await batch.commit();
      const newDocRef = doc(collection(db, "subscription")); // get doc ref
      await setDoc(newDocRef, subscriptionRecordToSubmit);

      // reseting states
      setIsLoading(false);
      setCreatingNewSubscription(false);
      setSubscriptionData((prevState) => [
        ...prevState,
        {
          ...subscriptionRecordToSubmit,
          nextBillingDate: billingDate,
          id: newDocRef.id,
        },
      ]);
      setDate(moment().format("YYYY-MM-DD"));
      setDataInput(initialState);
    } catch (err) {}
  }; // end handleSubmit

  const handleDelete = async (id) => {
    setIsLoading(true);

    //-------------------- demo mode only -------------------------------------
    if (!currentUser && onDemo) {
      setSubscriptionData(subscriptionData.filter((item) => item.id !== id));
      setIsLoading(false);
      return;
    } //-------------------- demo mode only -------------------------------------

    try {
      await deleteDoc(doc(db, "subscription", id));
      setSubscriptionData(subscriptionData.filter((item) => item.id !== id));
    } catch (err) {}
    setIsLoading(false);
  }; // end handleDelete

  const onChangeDate = (e) => {
    const newDate = moment(e.target.value).format("YYYY-MM-DD");
    setDate(newDate);
  }; // end onChangeDate

  const handleFilterFrequency = (frequency) => {
    if (selectedFrequency !== frequency) {
      setSelectedFrequency(frequency);
    } else {
      setSelectedFrequency("");
    }
  };

  useEffect(() => {
    document.title = "Expense Aware: Subscriptions";
  }, []);

  return (
    <div className="subscription">
      <h1 className="pageTitle">Recurring</h1>
      <div className="summary">
        <div
          className="summaryWidgetContainer"
          onClick={() => handleFilterFrequency("Weekly")}
        >
          <SummaryWidget
            title="Weekly"
            amount={summarySubscription.weeklyTotal}
            label={"/Week"}
            selectedFrequency={selectedFrequency === "Weekly"}
          />
        </div>
        <div
          className="summaryWidgetContainer"
          onClick={() => handleFilterFrequency("Monthly")}
        >
          <SummaryWidget
            title="Monthly"
            amount={summarySubscription.monthlyTotal}
            label={"/Month"}
            selectedFrequency={selectedFrequency === "Monthly"}
          />
        </div>
        <div
          className="summaryWidgetContainer"
          onClick={() => handleFilterFrequency("Quarterly")}
        >
          <SummaryWidget
            title="Quarterly"
            amount={summarySubscription.quarterlyTotal}
            label={"/Quarter"}
            selectedFrequency={selectedFrequency === "Quarterly"}
          />
        </div>
        <div
          className="summaryWidgetContainer"
          onClick={() => handleFilterFrequency("Annual")}
        >
          <SummaryWidget
            title="Yearly"
            amount={summarySubscription.annualTotal}
            label={"/Year"}
            selectedFrequency={selectedFrequency === "Annual"}
          />
        </div>
      </div>
      <hr></hr>
      <div className="subscriptionsList">
        {!creatingNewSubscription &&
        (currentUserInfo?.membershipType === "pro" ||
          subscriptionList.length < 15) ? (
          <div className="col">
            <div
              className="createNewContainer"
              onClick={() => setCreatingNewSubscription(true)}
            >
              <AddOutlined className="icon" />
              <span>Add recurring</span>
            </div>
          </div>
        ) : (
          <>
            {!creatingNewSubscription && (
              <div className="col">
                <div className="upgradeMembership">
                  <LockOutlined className="icon" />
                  <span>Add recurring</span>
                  {/* <div className="proIcon">Pro</div> */}
                </div>
              </div>
            )}
          </>
        )}
        {creatingNewSubscription &&
          (currentUserInfo?.membershipType === "pro" ||
            subscriptionList.length < 15) && (
            <div className="col">
              <div className="createNewSubscriptionWidget">
                <form onSubmit={handleSubmit}>
                  <div className="top">
                    <div className="title">
                      <div className="subscriptionIconContainer">
                        <div className="roundBackground">
                          <img src={dataInput.imageUrl} alt="" />
                        </div>
                      </div>
                      <div className="inputsTop">
                        <input
                          placeholder="Recurring Title"
                          id="name"
                          value={dataInput["name"]}
                          onChange={handleInput}
                          required
                        />
                        <input
                          placeholder="Image url..."
                          id="imageUrl"
                          value={dataInput["imageUrl"]}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <div className="details">
                      <input
                        placeholder="Vendor"
                        id="vendor"
                        value={dataInput["vendor"]}
                        onChange={handleInput}
                      />
                      <textarea
                        className="description"
                        rows={3}
                        placeholder="Description..."
                        id="description"
                        value={dataInput["description"]}
                        onChange={handleInput}
                      />
                      <select
                        id="accountId"
                        name="accountId"
                        value={dataInput["accountId"]}
                        onChange={handleInput}
                        required
                      >
                        <option disabled value="">
                          {" "}
                          -- select account --{" "}
                        </option>
                        {accounts.map((account) => (
                          <option value={account.id} key={account.id}>
                            {account.name}
                          </option>
                        ))}
                      </select>
                      <select
                        id="category"
                        name="category"
                        value={dataInput["category"]}
                        onChange={handleInput}
                        required
                      >
                        <option disabled value="">
                          {" "}
                          -- select category --{" "}
                        </option>
                        {userCategories.map(
                          (category, index) =>
                            !category.hidden && (
                              <option
                                key={`${category.label}.${index}`}
                                value={category.label}
                              >
                                {`${category.icon} ${category.label}`}
                              </option>
                            )
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="row">
                      <div className="subscriptionStartDate">
                        <label>Start date</label>
                        <input
                          id="date"
                          name="date"
                          type="date"
                          min="2015-01-01"
                          value={date}
                          onChange={onChangeDate}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <label>Payment Method</label>
                      <input
                        placeholder="e.g. Paypal"
                        id="paymentMethod"
                        value={dataInput["paymentMethod"]}
                        onChange={handleInput}
                        required
                      />
                    </div>
                    <div className="row">
                      <div className="subscriptionAmount">
                        <label>Cost per billing</label>
                        <input
                          id="cost"
                          name="cost"
                          type="text"
                          placeholder="0.00"
                          inputMode="decimal"
                          pattern="^[+-]?([0-9]{1,3}(,[0-9]{3})*(\.[0-9]+)?|\d*\.\d+|\d+)$"
                          value={dataInput["cost"]}
                          onChange={handleInput}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <label>Billing Cycle</label>
                      <select
                        id="billingCycle"
                        name="billingCycle"
                        value={dataInput["billingCycle"]}
                        onChange={handleInput}
                        required
                      >
                        <option disabled value="">
                          {" "}
                          -- Billing Cycle --{" "}
                        </option>
                        <option value="Start of Period">Start of Period</option>
                        <option value="End of Period">End of Period</option>
                      </select>
                    </div>
                    <div className="row">
                      <label>Billing Frequency</label>
                      <select
                        id="frequency"
                        name="frequency"
                        value={dataInput["frequency"]}
                        onChange={handleInput}
                        required
                      >
                        <option disabled value="">
                          {" "}
                          -- Frequency --{" "}
                        </option>
                        {paymentFrequencies.map((paymentFrequency) => (
                          <option
                            key={`${paymentFrequency}`}
                            value={paymentFrequency}
                          >
                            {paymentFrequency}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="row">
                      <div className="note">
                        Expenses related to this will automatically be recorded
                        from start date based on billing cycle and frequency.
                      </div>
                    </div>
                  </div>
                  <div className="buttons">
                    <button
                      className="submitButton"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress
                          size={"1.275rem"}
                          className="spinner"
                        />
                      ) : (
                        <>
                          <CheckOutlined className="icon" />
                        </>
                      )}
                    </button>
                    <button
                      className="cancelButton"
                      onClick={() => setCreatingNewSubscription(false)}
                    >
                      <NotInterestedOutlined className="icon" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

        {subscriptionList
          .sort((item1, item2) => item1.nextBillingDate - item2.nextBillingDate)
          .map((subscriptionItem) => {
            return (
              <div className="col" key={subscriptionItem.id}>
                <div className="subscriptionsContainer">
                  <SubscriptionWidget
                    subscriptionItem={subscriptionItem}
                    setConfirmDelete={setConfirmDelete}
                    userCategories={userCategories}
                    setSubscriptionData={setSubscriptionData}
                    onDemo={onDemo}
                    currentUser={currentUser}
                  />
                </div>
              </div>
            );
          })}
      </div>

      <AnimatePresence>
        {confirmDelete.showMessage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
            }}
            className="confirmDelete"
          >
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{
                scale: 1,
                opacity: 1,
                transition: {
                  delay: 0.1,
                  duration: 0.4,
                  type: "spring",
                  ease: [0.36, 0.66, 0.04, 1],
                },
              }}
              exit={{
                scale: 0.8,
                opacity: 0,
                transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
              }}
              className="confirmDeleteContainer"
            >
              <h1>Are you sure you want to delete subscription?</h1>
              <h3>{confirmDelete.name}</h3>
              <p>All previous expense records will not be deleted.</p>
              <div className="row">
                <button
                  className="btn-cancel"
                  onClick={() =>
                    setConfirmDelete({ showMessage: false, id: "" })
                  }
                >
                  Cancel
                </button>
                <button
                  className="deleteButton"
                  disabled={isLoading}
                  onClick={() => {
                    handleDelete(confirmDelete.id);
                    setConfirmDelete({ showMessage: false, id: "" });
                  }}
                >
                  {isLoading ? (
                    <CircularProgress size={"1.275rem"} className="spinner" />
                  ) : (
                    <>Delete</>
                  )}
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Subscription;
