import "./project.scss";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Datatable from "../../components/datatable/Datatable";
import UserProjectsContext from "../../context/UserProjectsContext";
import DeleteProjectModal from "./DeleteProjectModal";
import {
  CheckOutlined,
  DeleteOutline,
  EditOutlined,
  NotInterested,
} from "@mui/icons-material";
import CategoryTotals from "./CategoryTotals";
import TransactionContext from "../../context/transactionContext";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import useMeasure from "react-use-measure";

const Project = () => {
  const { projects, deleteProject, updateProjectName } =
    useContext(UserProjectsContext);
  const currency = useSelector((state) => state.userSettings.currency);
  const { data } = useContext(TransactionContext);
  const { sheetId } = useParams();
  const navigate = useNavigate();
  let [ref, { height }] = useMeasure();
  const [projectDetails, setProjectDetails] = useState({
    id: "",
    sheetName: "",
  });

  const [confirmDelete, setConfirmDelete] = useState({
    showMessage: false,
    id: "",
    name: "",
  });
  const [dataInput, setDataInput] = useState({
    newProjectName: "",
  });
  const [isLoading, setIsloading] = useState(false);
  const [isEditingSheetName, setIsEditingSheetName] = useState(false);
  const [totalSpent, setTotalSpent] = useState({
    wholeNumber: "0",
    cent: "",
  });

  const [categoryAmountTotals, setCategoryAmountTotals] = useState({});
  const [showCategory, setShowCategory] = useState(false);

  const handleDeleteProject = (id) => {
    setIsloading(true);

    deleteProject(id);

    setIsloading(false);
    // navigate("/expenses")
  };

  const handleCancelEditSheetName = (e) => {
    e.preventDefault();
    setIsEditingSheetName(false);
    setDataInput((prevState) => ({
      ...prevState,
      newProjectName: projectDetails.sheetName,
    }));
  };

  const handleSubmitNewProjectName = (e) => {
    e.preventDefault();
    setIsloading(true);

    updateProjectName(projectDetails.id, dataInput.newProjectName);
    setDataInput((prevState) => ({
      ...prevState,
      newProjectName: dataInput.newProjectName,
    }));
    setIsloading(false);
    setIsEditingSheetName(false);
  };

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setDataInput((prevState) => ({ ...prevState, [id]: value }));
  };

  useEffect(() => {
    const project = projects.find((item) => item.id === sheetId);
    if (project) {
      setProjectDetails({
        sheetName: project?.name,
        id: project?.id,
      });
      setDataInput((prevState) => ({
        ...prevState,
        newProjectName: project?.name,
      }));

      const groupedActual = data
        .filter((item) => item.sheetId === sheetId)
        .reduce(
          (obj, item) => {
            if (!obj.categoryTotal[item.category]) {
              obj.categoryTotal[item.category] = 0;
            }
            obj["totalAmount"] += item.amount;
            obj.categoryTotal[item.category] += item.amount;
            return obj;
          },
          { totalAmount: 0, categoryTotal: {} }
        );

      const [wholeNumber, cent] = groupedActual.totalAmount
        ?.toLocaleString()
        ?.split(".");
      setTotalSpent({ wholeNumber: wholeNumber, cent: cent });
      setCategoryAmountTotals(groupedActual.categoryTotal);
    } else {
      setProjectDetails({
        sheetName: "",
        id: "",
      });
    }
  }, [sheetId, projects, data]);

  useEffect(() => {
    document.title = `Expense Aware: ${projectDetails.sheetName}`;
  }, [projectDetails.sheetName]);
  return (
    <div className="project">
      {projectDetails.id && (
        <div className="row">
          {!isEditingSheetName ? (
            <>
              <h1 className="pageTitle">{projectDetails.sheetName}</h1>
              <div className="actions">
                <button
                  className="editButton"
                  onClick={() => setIsEditingSheetName(true)}
                >
                  <EditOutlined />
                </button>
                <button
                  className="deleteButton"
                  disabled={isLoading}
                  onClick={() =>
                    setConfirmDelete({
                      showMessage: true,
                      id: projectDetails.id,
                      name: projectDetails.sheetName,
                    })
                  }
                >
                  <DeleteOutline />
                </button>
              </div>
            </>
          ) : (
            <form
              className="projectEditNameForm"
              onSubmit={handleSubmitNewProjectName}
            >
              <input
                type="text"
                id="newProjectName"
                value={dataInput["newProjectName"]}
                onChange={handleInput}
              />
              <div className="actions">
                <button
                  className="submitButton"
                  type="submit"
                  disabled={dataInput["newProjectName"].length < 1 || isLoading}
                >
                  <CheckOutlined className="icon" />
                </button>
                <button
                  className="cancelButton"
                  onClick={handleCancelEditSheetName}
                >
                  <NotInterested className="icon" />
                </button>
              </div>
            </form>
          )}
        </div>
      )}
      {projectDetails.id && (
        <>
          <div className="gridContainer">
            <div className="leftContainer">
              <Datatable />
              <AnimatePresence>
                {confirmDelete.showMessage && (
                  <DeleteProjectModal
                    confirmDelete={confirmDelete}
                    setConfirmDelete={setConfirmDelete}
                    handleDeleteProject={handleDeleteProject}
                    isLoading={isLoading}
                  />
                )}
              </AnimatePresence>
            </div>
            <div className="rightContainer">
              <div className="title">Overview</div>
              <div className="totals">
                <div className="totalSpent">Total Spent</div>
                <div className="amountTotals">
                  <span className="currency">{currency.symbol}</span>
                  {totalSpent.wholeNumber}
                  {totalSpent.cent && (
                    <span className="cent">.{totalSpent.cent}</span>
                  )}
                </div>
              </div>
              <hr className="lineBreak" />
              <div className="row">
                <div className="category">Per category</div>
                <button
                  className="showCategoryToggle expandToggleBtn"
                  onClick={() => setShowCategory(!showCategory)}
                >
                  {showCategory ? "Hide" : "Show"}
                </button>
              </div>
              <motion.div
                animate={{
                  height: height,
                  transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
                }}
                className="overflowHidden"
              >
                <div ref={ref}>
                  <CategoryTotals
                    categoryAmountTotals={categoryAmountTotals}
                    showCategory={showCategory}
                    type="expense"
                  />
                </div>
              </motion.div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Project;
