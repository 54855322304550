import { useEffect } from "react"
import "./errorfallback.scss"
import iconLogo from "../../images/iconLogo.webp"

const ErrorFallback = () => {
    useEffect(()=>{
        document.title = "Expense Aware | Error"
      },[])
  return (
    <div className='errorfallback'>
        <div className="logoContainer">
            <img src={iconLogo} alt="" className='logoImage'/>
        </div>
      <h1>Error</h1>
      <h2>Something went wrong.</h2>
    <button className="backToHome" onClick={() => window.location.reload()}>Refresh</button>
    </div>
  )
}

export default ErrorFallback