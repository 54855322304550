import { ChevronLeft, ChevronRight, ExpandMore } from "@mui/icons-material";
import "./periodselection.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  nextPeriod,
  previousPeriod,
  selectMonthOption,
  selectPeriodicDisplay,
} from "../../redux/userSettingsSlice";
import { useContext } from "react";
import BudgetContext from "../../context/BudgetContext";
import { useState } from "react";
import UserSettingsContext from "../../context/UserSettingsContext";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { useRef } from "react";

const PeriodSelection = (props) => {
  const { shortTitle } = props;
  const { changeYear } = useContext(BudgetContext);
  const { months } = useContext(UserSettingsContext);
  const dispatch = useDispatch();
  const { year, month, periodicDisplay } = useSelector(
    (state) => state.userSettings.options
  );
  const dropdownRef = useRef();
  const [toggleMenu, setToggleMenu] = useState(false);

  const handlePreviousPeriod = () => {
    dispatch(previousPeriod());
    if (periodicDisplay === "yearly" || month === "january") {
      const previousYearFloat = parseFloat(year) - 1;
      changeYear(previousYearFloat.toString());
    }
  };

  const handleNextPeriod = () => {
    dispatch(nextPeriod());
    if (periodicDisplay === "yearly" || month === "december") {
      const nextYearFloat = parseFloat(year) + 1;
      changeYear(nextYearFloat.toString());
    }
  };

  const handlePeriodicDisplaySelect = (e) => {
    if (e.target.id === "year") {
      dispatch(selectPeriodicDisplay("yearly"));
    } else if (e.target.id === "month") {
      dispatch(selectPeriodicDisplay("monthly"));
    }
  };

  const handleSelectMonth = (e) => {
    dispatch(selectMonthOption(e.target.id));
    setToggleMenu(false);
  };

  const handleToggleMonthMenu = () => {
    setToggleMenu((prevState) => !prevState);
  };

  useEffect(() => {
    const handler = (e) => {
      if (!dropdownRef?.current?.contains(e.target)) {
        setToggleMenu(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [dropdownRef]);

  return (
    <div className="optionsContainer">
      <div className="navigatePeriod">
        <div className="buttonGroup">
          <button className="btn-primary" onClick={handlePreviousPeriod}>
            <ChevronLeft className="icon" />
          </button>
          <button className="btn-primary" onClick={handleNextPeriod}>
            <ChevronRight className="icon" />
          </button>
        </div>
        <div
          className={`dropdown ${periodicDisplay === "yearly" ? "hidden" : ""}`}
          ref={dropdownRef}
        >
          <div className="currentPeriod" onClick={handleToggleMonthMenu}>
            {month}
            <ExpandMore className="icon" />
          </div>
          <AnimatePresence>
            {toggleMenu && (
              <motion.div
                initial={{ opacity: 0, y: "-20%" }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
                }}
                exit={{
                  opacity: 0,
                  y: "-20%",
                  transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
                }}
                className="dropdownMenu"
              >
                {months.map((month) => {
                  return (
                    <div
                      className="menuItem"
                      key={month}
                      onClick={handleSelectMonth}
                      id={month}
                    >
                      {month}
                    </div>
                  );
                })}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div className="periodTitle">
        {`${
          periodicDisplay === "monthly"
            ? shortTitle
              ? month?.substring(0, 3)
              : month
            : ""
        } ${year}`}
      </div>
      <div className="buttonGroup">
        <button
          className={`btn-primary ${
            periodicDisplay === "yearly" ? "active" : "inactive"
          }`}
          id={"year"}
          onClick={handlePeriodicDisplaySelect}
        >
          Year
        </button>
        <button
          className={`btn-primary ${
            periodicDisplay === "monthly" ? "active" : "inactive"
          }`}
          id={"month"}
          onClick={handlePeriodicDisplaySelect}
        >
          {" "}
          Month
        </button>
      </div>
    </div>
  );
};

export default PeriodSelection;
