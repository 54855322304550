import { CircularProgress } from "@mui/material";
import "./confirmdeletemodal.scss";
import { motion } from "framer-motion";

const ConfirmDeleteModal = (props) => {
  const {
    handleDelete,
    confirmDelete,
    setConfirmDelete,
    title,
    description,
    isLoading,
    note,
  } = props;
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
      }}
      exit={{
        opacity: 0,
        transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
      }}
      className="confirmdeletemodal"
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          transition: {
            duration: 0.4,
            type: "spring",
            ease: [0.36, 0.66, 0.04, 1],
          },
        }}
        exit={{
          scale: 0.8,
          opacity: 0,
          transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
        }}
        className="confirmDeleteContainer"
      >
        <h1>{title}</h1>
        {description && <h3>{description}</h3>}
        {note && <p>{note}</p>}
        <div className="row">
          <button
            className="btn-cancel"
            onClick={() => setConfirmDelete({ showMessage: false, id: "" })}
          >
            Cancel
          </button>
          <button
            className="deleteButton"
            onClick={() => {
              handleDelete(confirmDelete.id);
            }}
          >
            {isLoading ? (
              <CircularProgress size={"1.275rem"} className="spinner" />
            ) : (
              <>
                <span>Delete</span>
              </>
            )}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ConfirmDeleteModal;
