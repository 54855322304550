import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Suspense, lazy, useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import "./styles/darkmode.scss";
import PageNotFound from "./pages/pagenotfound/PageNotFound";
import LazyLoading from "./components/lazyloading/LazyLoading";
import Transaction from "./pages/transactions/Transaction";
import Budget from "./pages/budget/Budget";
import Subscription from "./pages/subscription/Subscription";
import Income from "./pages/income/Income";
import Project from "./pages/project/Project";
import Accounts from "./pages/accounts/Accounts";
import ErrorFallback from "./components/errorboundary/ErrorFallback";
import PfTools from "./pages/pftools/PfTools";
import PfToolsRouter from "./pages/pftools/PfToolsRouter";
import AccountTransfers from "./pages/accounttransfers/AccountTransfers";

const Layout = lazy(() => import("./components/layout/Layout"));
const AppEntrance = lazy(() => import("./components/appentrance/AppEntrance"));
const LandingPage = lazy(() => import("./pages/landingPage/LandingPage"));
const Login = lazy(() => import("./pages/login/Login"));
const Register = lazy(() => import("./pages/register/Register"));
const TermsAndConditions = lazy(() =>
  import("./pages/termsandconditions/TermsAndConditions")
);
const ForgotPassword = lazy(() =>
  import("./pages/forgotPassword/ForgotPassword")
);
const WhatsNew = lazy(() => import("./pages/whatsNew/WhatsNew"));
const EditProfile = lazy(() => import("./pages/editProfile/EditProfile"));
const Feedback = lazy(() => import("./pages/feedback/Feedback"));
const Home = lazy(() => import("./pages/home/Home"));
const Projects = lazy(() => import("./pages/project/Projects"));
const Categories = lazy(() => import("./pages/categories/Categories"));
const Faqs = lazy(() => import("./pages/faqs/Faqs"));
const LoanCalculator = lazy(() =>
  import("./pages/pftools/loancalculator/LoanCalculator")
);
const FireCalculator = lazy(() =>
  import("./pages/pftools/firecalculator/FireCalculator")
);

function App() {
  const { currentUser, onDemo } = useContext(AuthContext);

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to={"/login"} />;
    }

    return children;
  };

  // allow route only on demo and without a user
  const DemoRoute = ({ children }) => {
    if (currentUser || !onDemo) {
      if (currentUser) {
        alert("You are currently logged in. Log out, to go on Demo Mode.");
      }
      return <Navigate to={"/login"} />;
    }

    return children;
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: !currentUser ? (
        <Suspense fallback={<LazyLoading />}>
          <AppEntrance />
        </Suspense>
      ) : (
        <Navigate to={"/my-dashboard"} />
      ),
      children: [
        {
          path: "/",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <LandingPage />
            </Suspense>
          ),
        },
        {
          path: "/login",
          element: !currentUser ? (
            <Suspense fallback={<LazyLoading />}>
              <Login />
            </Suspense>
          ) : (
            <Navigate to={"/my-dashboard"} />
          ),
        },
        {
          path: "/register",
          element: !currentUser ? (
            <Suspense fallback={<LazyLoading />}>
              <Register />
            </Suspense>
          ) : (
            <Navigate to={"/my-dashboard"} />
          ),
        },
        {
          path: "/forgot",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <ForgotPassword />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/my-dashboard",
      element: (
        <ProtectedRoute>
          <Suspense fallback={<LazyLoading />}>
            <Layout />
          </Suspense>
        </ProtectedRoute>
      ),
      errorElement: <ErrorFallback />,
      children: [
        {
          path: "/my-dashboard",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Home />
            </Suspense>
          ),
        },
        {
          path: "/my-dashboard/expenses",
          element: (
            // <Suspense fallback={<LazyLoading/>}>
            <Transaction />
            // </Suspense>
          ),
        },
        {
          path: "/my-dashboard/subscriptions",
          element: (
            // <Suspense fallback={<LazyLoading/>}>
            <Subscription />
            // </Suspense>
          ),
        },
        {
          path: "/my-dashboard/budget",
          element: (
            // <Suspense fallback={<LazyLoading/>}>
            <Budget />
            // </Suspense>
          ),
        },
        {
          path: "/my-dashboard/income",
          element: (
            // <Suspense fallback={<LazyLoading/>}>
            <Income />
            // </Suspense>
          ),
        },
        {
          path: "/my-dashboard/accounttransfers",
          element: (
            // <Suspense fallback={<LazyLoading/>}>
            <AccountTransfers />
            // </Suspense>
          ),
        },
        {
          path: "/my-dashboard/expenses/projects/",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Projects />
            </Suspense>
          ),
        },
        {
          path: "/my-dashboard/expenses/project/:sheetId",
          element: (
            // <Suspense fallback={<LazyLoading/>}>
            <Project />
            // </Suspense>
          ),
        },
        {
          path: "/my-dashboard/categories",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Categories />
            </Suspense>
          ),
        },
        {
          path: "/my-dashboard/my-accounts",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Accounts />
            </Suspense>
          ),
        },
        {
          path: "/my-dashboard/pf-tools",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <PfToolsRouter />
            </Suspense>
          ),
          children: [
            {
              path: "/my-dashboard/pf-tools/",
              element: (
                <Suspense fallback={<LazyLoading />}>
                  <PfTools />
                </Suspense>
              ),
            },
            {
              path: "/my-dashboard/pf-tools/loan-calculator",
              element: (
                <Suspense fallback={<LazyLoading />}>
                  <LoanCalculator />
                </Suspense>
              ),
            },
            {
              path: "/my-dashboard/pf-tools/fire-calculator",
              element: (
                <Suspense fallback={<LazyLoading />}>
                  <FireCalculator />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "/my-dashboard/account",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <EditProfile />
            </Suspense>
          ),
        },
        {
          path: "/my-dashboard/feedback",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Feedback />
            </Suspense>
          ),
        },
        {
          path: "/my-dashboard/frequently-asked-questions",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Faqs />
            </Suspense>
          ),
        },
        {
          path: "/my-dashboard/whats-new",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <WhatsNew />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/terms-and-privacy",
      element: (
        <Suspense fallback={<LazyLoading />}>
          <TermsAndConditions />
        </Suspense>
      ),
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
    {
      path: "/demo",
      element: (
        <DemoRoute>
          <Suspense fallback={<LazyLoading />}>
            <Layout />
          </Suspense>
        </DemoRoute>
      ),
      errorElement: <ErrorFallback />,
      children: [
        {
          path: "/demo",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Home />
            </Suspense>
          ),
        },
        {
          path: "/demo/expenses",
          element: (
            // <Suspense fallback={<LazyLoading/>}>
            <Transaction />
            // </Suspense>
          ),
        },
        {
          path: "/demo/subscriptions",
          element: (
            // <Suspense fallback={<LazyLoading/>}>
            <Subscription />
            // </Suspense>
          ),
        },
        {
          path: "/demo/budget",
          element: (
            // <Suspense fallback={<LazyLoading/>}>
            <Budget />
            // </Suspense>
          ),
        },
        {
          path: "/demo/income",
          element: (
            // <Suspense fallback={<LazyLoading/>}>
            <Income />
            // </Suspense>
          ),
        },
        {
          path: "/demo/accounttransfers",
          element: (
            // <Suspense fallback={<LazyLoading/>}>
            <AccountTransfers />
            // </Suspense>
          ),
        },
        {
          path: "/demo/expenses/projects/",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Projects />
            </Suspense>
          ),
        },
        {
          path: "/demo/expenses/project/:sheetId",
          element: (
            // <Suspense fallback={<LazyLoading/>}>
            <Project />
            // </Suspense>
          ),
        },
        {
          path: "/demo/categories",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Categories />
            </Suspense>
          ),
        },
        {
          path: "/demo/my-accounts",
          element: (
            // <Suspense fallback={<LazyLoading/>}>
            <Accounts />
            // </Suspense>
          ),
        },
        {
          path: "/demo/pf-tools",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <PfToolsRouter />
            </Suspense>
          ),
          children: [
            {
              path: "/demo/pf-tools/",
              element: (
                <Suspense fallback={<LazyLoading />}>
                  <PfTools />
                </Suspense>
              ),
            },
            {
              path: "/demo/pf-tools/loan-calculator",
              element: (
                <Suspense fallback={<LazyLoading />}>
                  <LoanCalculator />
                </Suspense>
              ),
            },
            {
              path: "/demo/pf-tools/fire-calculator",
              element: (
                <Suspense fallback={<LazyLoading />}>
                  <FireCalculator />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "/demo/frequently-asked-questions",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <Faqs />
            </Suspense>
          ),
        },
        {
          path: "/demo/whats-new",
          element: (
            <Suspense fallback={<LazyLoading />}>
              <WhatsNew />
            </Suspense>
          ),
        },
      ],
    },
  ]);

  return (
    <div className="app">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
