import React from "react";
import "./emojiicon.scss";

const EmojiIcon = (props) => {
  const { emoji = null, size = "" } = props;
  return (
    <div className={`emojiIconContainer ${size}`}>
      <div className="emojiIcon">{emoji}</div>
    </div>
  );
};

export default EmojiIcon;
