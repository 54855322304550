import "./categoryicon.scss"

import { 
  CardGiftcardOutlined as CardGiftcardOutlinedIcon, 
  CheckroomOutlined as CheckroomOutlinedIcon,
  ChildFriendlyOutlined as ChildFriendlyOutlinedIcon, 
  CottageOutlined as CottageOutlinedIcon, 
  ElectricBoltOutlined as ElectricBoltOutlinedIcon, 
  FaceRetouchingNaturalOutlined as FaceRetouchingNaturalOutlinedIcon, 
  GppGoodOutlined as GppGoodOutlinedIcon, 
  HealthAndSafetyOutlined as HealthAndSafetyOutlinedIcon, 
  KitchenOutlined as KitchenOutlinedIcon, 
  LiveTvOutlined as LiveTvOutlinedIcon, 
  PetsOutlined as PetsOutlinedIcon, 
  ReceiptLongOutlined as ReceiptLongOutlinedIcon, 
  TrainOutlined as TrainOutlinedIcon, 
  VolunteerActivismOutlined as VolunteerActivismOutlinedIcon,
  WorkOutlineOutlined as WorkOutlineOutlinedIcon,
  PercentOutlined as PercentOutlinedIcon,
  CurrencyExchangeOutlined as CurrencyExchangeOutlinedIcon,
  AddchartOutlined as AddchartOutlinedIcon,
  ApartmentOutlined as ApartmentOutlinedIcon,
  PaidOutlined as PaidOutlinedIcon,
  DevicesOutlined as DevicesOutlinedIcon,
  RamenDiningOutlined as RamenDiningOutlinedIcon,
  TagOutlined as TagOutlinedIcon,
  KayakingOutlined as KayakingOutlinedIcon,
  BathtubOutlined as BathtubOutlinedIcon,
  BedOutlined as BedOutlinedIcon,
  BusinessOutlined as BusinessOutlinedIcon,
  StorefrontOutlined as StorefrontOutlinedIcon,
  CakeOutlined as CakeOutlinedIcon,
  CameraAltOutlined as CameraAltOutlinedIcon,
  ChurchOutlined as ChurchOutlinedIcon,
  FreeBreakfastOutlined as FreeBreakfastOutlinedIcon,
  BikeScooterOutlined as BikeScooterOutlinedIcon,
  DirectionsCarFilledOutlined as DirectionsCarFilledOutlinedIcon,
  CommuteOutlined as CommuteOutlinedIcon,
  ComputerOutlined as ComputerOutlinedIcon,
  HandymanOutlined as HandymanOutlinedIcon,
  DeliveryDiningOutlined as DeliveryDiningOutlinedIcon,
  LocalDiningOutlined as LocalDiningOutlinedIcon,
  LocalDrinkOutlined as LocalDrinkOutlinedIcon,
  LocalBarOutlined as LocalBarOutlinedIcon,
  EmailOutlined as EmailOutlinedIcon,
  FaceOutlined as FaceOutlinedIcon,
  Face3Outlined as Face3OutlinedIcon,
  FamilyRestroomOutlined as FamilyRestroomOutlinedIcon,
  AgricultureOutlined as AgricultureOutlinedIcon,
  MaleOutlined as MaleOutlinedIcon,
  FemaleOutlined as FemaleOutlinedIcon,
  FitnessCenterOutlined as FitnessCenterOutlinedIcon,
  FlightOutlined as FlightOutlinedIcon,
  ForestOutlined as ForestOutlinedIcon,
  FastfoodOutlined as FastfoodOutlinedIcon,
  HeadphonesOutlined as HeadphonesOutlinedIcon,
  IcecreamOutlined as IcecreamOutlinedIcon,
  FavoriteBorderOutlined as FavoriteBorderOutlinedIcon,
  MonetizationOnOutlined as MonetizationOnOutlinedIcon,
  ColorLensOutlined as ColorLensOutlinedIcon,
  WeekendOutlined as WeekendOutlinedIcon,
  PhoneIphoneOutlined as PhoneIphoneOutlinedIcon,
  SavingsOutlined as SavingsOutlinedIcon,
  LocalShippingOutlined as LocalShippingOutlinedIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
  SportsBasketballOutlined as SportsBasketballOutlinedIcon,
  SportsFootballOutlined as SportsFootballOutlinedIcon,
  SportsEsportsOutlined as SportsEsportsOutlinedIcon,
  EmojiEventsOutlined as EmojiEventsOutlinedIcon,
  StarBorderOutlined as StarBorderOutlinedIcon,
  VapingRoomsOutlined as VapingRoomsOutlinedIcon,
  WatchOutlined as WatchOutlinedIcon,
  WorkOutline as WorkOutlineIcon,

   } from "@mui/icons-material";

const iconsLibrary = {
  CardGiftcardOutlinedIcon: CardGiftcardOutlinedIcon,
  CheckroomOutlinedIcon: CheckroomOutlinedIcon,
  ChildFriendlyOutlinedIcon: ChildFriendlyOutlinedIcon,
  CottageOutlinedIcon: CottageOutlinedIcon,
  ElectricBoltOutlinedIcon: ElectricBoltOutlinedIcon,
  FaceRetouchingNaturalOutlinedIcon: FaceRetouchingNaturalOutlinedIcon,
  GppGoodOutlinedIcon: GppGoodOutlinedIcon,
  HealthAndSafetyOutlinedIcon: HealthAndSafetyOutlinedIcon,
  KitchenOutlinedIcon: KitchenOutlinedIcon,
  LiveTvOutlinedIcon: LiveTvOutlinedIcon,
  PetsOutlinedIcon: PetsOutlinedIcon,
  ReceiptLongOutlinedIcon: ReceiptLongOutlinedIcon,
  TrainOutlinedIcon: TrainOutlinedIcon,
  VolunteerActivismOutlinedIcon: VolunteerActivismOutlinedIcon,
  WorkOutlineOutlinedIcon: WorkOutlineOutlinedIcon,
  PercentOutlinedIcon: PercentOutlinedIcon,
  CurrencyExchangeOutlined: CurrencyExchangeOutlinedIcon,
  AddchartOutlinedIcon: AddchartOutlinedIcon,
  ApartmentOutlinedIcon: ApartmentOutlinedIcon,
  PaidOutlinedIcon: PaidOutlinedIcon,
  DevicesOutlinedIcon: DevicesOutlinedIcon,
  RamenDiningOutlinedIcon: RamenDiningOutlinedIcon,
  hashtag: TagOutlinedIcon,
  kayak: KayakingOutlinedIcon,
  bath: BathtubOutlinedIcon,
  business: BusinessOutlinedIcon,
  store: StorefrontOutlinedIcon,
  child: ChildFriendlyOutlinedIcon,
  church: ChurchOutlinedIcon,
  bike: BikeScooterOutlinedIcon,
  delivery: DeliveryDiningOutlinedIcon,
  car: DirectionsCarFilledOutlinedIcon,
  commute: CommuteOutlinedIcon,
  train: TrainOutlinedIcon,
  farm: AgricultureOutlinedIcon,
  shipping: LocalShippingOutlinedIcon,
  flight: FlightOutlinedIcon,
  camera: CameraAltOutlinedIcon,
  headphones: HeadphonesOutlinedIcon,
  computer: ComputerOutlinedIcon,
  phone: PhoneIphoneOutlinedIcon,
  devices: DevicesOutlinedIcon,
  television: LiveTvOutlinedIcon,
  watch: WatchOutlinedIcon,
  gitfts: CardGiftcardOutlinedIcon,
  work: WorkOutlineIcon,
  construction: HandymanOutlinedIcon,
  dining: LocalDiningOutlinedIcon,
  cake: CakeOutlinedIcon,
  icecream: IcecreamOutlinedIcon,
  food: FastfoodOutlinedIcon,
  ramen: RamenDiningOutlinedIcon,
  coffee: FreeBreakfastOutlinedIcon,
  drink: LocalDrinkOutlinedIcon,
  drink2: LocalBarOutlinedIcon,
  email: EmailOutlinedIcon,
  face1: FaceOutlinedIcon,
  face2: Face3OutlinedIcon,
  face3: FaceRetouchingNaturalOutlinedIcon,
  family: FamilyRestroomOutlinedIcon,
  pets: PetsOutlinedIcon,
  male: MaleOutlinedIcon,
  female: FemaleOutlinedIcon,
  love: FavoriteBorderOutlinedIcon,
  love2: VolunteerActivismOutlinedIcon,
  shield: HealthAndSafetyOutlinedIcon,
  forest: ForestOutlinedIcon,
  clothe: CheckroomOutlinedIcon,
  bed: BedOutlinedIcon,
  sofa: WeekendOutlinedIcon,
  fridge: KitchenOutlinedIcon,
  house: CottageOutlinedIcon,
  electricity: ElectricBoltOutlinedIcon,
  fitness: FitnessCenterOutlinedIcon,
  paint: ColorLensOutlinedIcon,
  money: MonetizationOnOutlinedIcon,
  moneyExchange: CurrencyExchangeOutlinedIcon,
  savings: SavingsOutlinedIcon,
  percent: PercentOutlinedIcon,
  chart: AddchartOutlinedIcon,
  shopping: ShoppingCartOutlinedIcon,
  basketball: SportsBasketballOutlinedIcon,
  football: SportsFootballOutlinedIcon,
  gaming: SportsEsportsOutlinedIcon,
  trophy: EmojiEventsOutlinedIcon,
  star: StarBorderOutlinedIcon,
  vape: VapingRoomsOutlinedIcon,
  }
  // hashtag: lazy(() => import('@mui/icons-material').then((module) => ({default: module['TagOutlined'] }))),
  // kayak: lazy(() => import('@mui/icons-material').then((module) => ({default: module['KayakingOutlined'] }))),
  // bath: lazy(() => import('@mui/icons-material').then((module) => ({default: module['BathtubOutlined'] }))),
  // bed: lazy(() => import('@mui/icons-material').then((module) => ({default: module['BedOutlined'] }))),
  // business: lazy(() => import('@mui/icons-material').then((module) => ({default: module['BusinessOutlined'] }))),
  // store: lazy(() => import('@mui/icons-material').then((module) => ({default: module['StorefrontOutlined'] }))),
  // cake: lazy(() => import('@mui/icons-material').then((module) => ({default: module['CakeOutlined'] }))),
  // camera: lazy(() => import('@mui/icons-material').then((module) => ({default: module['CameraAltOutlined'] }))),
  // child: lazy(() => import('@mui/icons-material').then((module) => ({default: module['ChildFriendlyOutlined'] }))),
  // church: lazy(() => import('@mui/icons-material').then((module) => ({default: module['ChurchOutlined'] }))),
  // coffee: lazy(() => import('@mui/icons-material').then((module) => ({default: module['FreeBreakfastOutlined'] }))),
  // bike: lazy(() => import('@mui/icons-material').then((module) => ({default: module['BikeScooterOutlined'] }))),
  // car: lazy(() => import('@mui/icons-material').then((module) => ({default: module['DirectionsCarFilledOutlined'] }))),
  // commute: lazy(() => import('@mui/icons-material').then((module) => ({default: module['CommuteOutlined'] }))),
  // computer: lazy(() => import('@mui/icons-material').then((module) => ({default: module['ComputerOutlined'] }))),
  // construction: lazy(() => import('@mui/icons-material').then((module) => ({default: module['HandymanOutlined'] }))),
  // delivery: lazy(() => import('@mui/icons-material').then((module) => ({default: module['DeliveryDiningOutlined'] }))),
  // dining: lazy(() => import('@mui/icons-material').then((module) => ({default: module['LocalDiningOutlined'] }))),
  // drink: lazy(() => import('@mui/icons-material').then((module) => ({default: module['LocalDrinkOutlined'] }))),
  // drink2: lazy(() => import('@mui/icons-material').then((module) => ({default: module['LocalBarOutlined'] }))),
  // email: lazy(() => import('@mui/icons-material').then((module) => ({default: module['EmailOutlined'] }))),
  // face1: lazy(() => import('@mui/icons-material').then((module) => ({default: module['FaceOutlined'] }))),
  // face2: lazy(() => import('@mui/icons-material').then((module) => ({default: module['Face3Outlined'] }))),
  // family: lazy(() => import('@mui/icons-material').then((module) => ({default: module['FamilyRestroomOutlined'] }))),
  // pets: lazy(() => import('@mui/icons-material').then((module) => ({default: module['PetsOutlined'] }))),
  // farm: lazy(() => import('@mui/icons-material').then((module) => ({default: module['AgricultureOutlined'] }))),
  // male: lazy(() => import('@mui/icons-material').then((module) => ({default: module['MaleOutlined'] }))),
  // female: lazy(() => import('@mui/icons-material').then((module) => ({default: module['FemaleOutlined'] }))),
  // fitness: lazy(() => import('@mui/icons-material').then((module) => ({default: module['FitnessCenterOutlined'] }))),
  // flight: lazy(() => import('@mui/icons-material').then((module) => ({default: module['FlightOutlined'] }))),
  // forest: lazy(() => import('@mui/icons-material').then((module) => ({default: module['ForestOutlined'] }))),
  // food: lazy(() => import('@mui/icons-material').then((module) => ({default: module['FastfoodOutlined'] }))),
  // fridge: lazy(() => import('@mui/icons-material').then((module) => ({default: module['KitchenOutlined'] }))),
  // headphones: lazy(() => import('@mui/icons-material').then((module) => ({default: module['HeadphonesOutlined'] }))),
  // house: lazy(() => import('@mui/icons-material').then((module) => ({default: module['CottageOutlined'] }))),
  // icecream: lazy(() => import('@mui/icons-material').then((module) => ({default: module['IcecreamOutlined'] }))),
  // love: lazy(() => import('@mui/icons-material').then((module) => ({default: module['FavoriteBorderOutlined'] }))),
  // money: lazy(() => import('@mui/icons-material').then((module) => ({default: module['MonetizationOnOutlined'] }))),
  // paint: lazy(() => import('@mui/icons-material').then((module) => ({default: module['ColorLensOutlined'] }))),
  // sofa: lazy(() => import('@mui/icons-material').then((module) => ({default: module['WeekendOutlined'] }))),
  // phone: lazy(() => import('@mui/icons-material').then((module) => ({default: module['PhoneIphoneOutlined'] }))),
  // savings: lazy(() => import('@mui/icons-material').then((module) => ({default: module['SavingsOutlined'] }))),
  // shipping: lazy(() => import('@mui/icons-material').then((module) => ({default: module['LocalShippingOutlined'] }))),
  // shopping: lazy(() => import('@mui/icons-material').then((module) => ({default: module['ShoppingCartOutlined'] }))),
  // basketball: lazy(() => import('@mui/icons-material').then((module) => ({default: module['SportsBasketballOutlined'] }))),
  // football: lazy(() => import('@mui/icons-material').then((module) => ({default: module['SportsFootballOutlined'] }))),
  // gaming: lazy(() => import('@mui/icons-material').then((module) => ({default: module['SportsEsportsOutlined'] }))),
  // trophy: lazy(() => import('@mui/icons-material').then((module) => ({default: module['EmojiEventsOutlined'] }))),
  // star: lazy(() => import('@mui/icons-material').then((module) => ({default: module['StarBorderOutlined'] }))),
  // vape: lazy(() => import('@mui/icons-material').then((module) => ({default: module['VapingRoomsOutlined'] }))),
  // watch: lazy(() => import('@mui/icons-material').then((module) => ({default: module['WatchOutlined'] }))),
  // work: lazy(() => import('@mui/icons-material').then((module) => ({default: module['WorkOutline'] }))),  

const CategoryIcon = (props) => {
    const {Icon, color, backgroundColor, imageSource} = props
    const IconComponent = iconsLibrary[Icon]

  return (
    <div className='categoryIconContainer' style={{backgroundColor:backgroundColor}}>
        {IconComponent && <IconComponent className="icon" style={{color:color}}/>}
    </div>
  )
}

export default CategoryIcon