import {
  CheckOutlined,
  DeleteOutlineOutlined,
  ModeEditOutlineOutlined,
  MoreVertOutlined,
  NotInterestedOutlined,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { db } from "../../firebase";
import "./subscriptionwidget.scss";
import { useSelector } from "react-redux";
import useMeasure from "react-use-measure";
import { motion } from "framer-motion";
import { shadeColor } from "../../utils/utils";

const SubscriptionWidget = (props) => {
  const {
    subscriptionItem,
    setConfirmDelete,
    userCategories,
    setSubscriptionData,
    currentUser,
    onDemo,
  } = props;
  const currency = useSelector((state) => state.userSettings.currency);
  const accounts = useSelector((state) => state.accounts.userAccounts);
  const isDarkMode = useSelector((state) => state.userSettings.isDarkMode);
  const subscriptionData = {
    id: subscriptionItem.id,
    imageUrl: subscriptionItem.imageUrl,
    name: subscriptionItem.name,
    vendor: subscriptionItem.vendor,
    accountId: subscriptionItem.accountId,
    category: subscriptionItem.category,
    cost: subscriptionItem.cost,
    frequency: subscriptionItem.frequency,
    paymentMethod: subscriptionItem.paymentMethod,
    description: subscriptionItem.description,
    startDate: new Date(
      subscriptionItem.startDate.seconds * 1000
    ).toLocaleDateString(undefined, { timeZone: "UTC" }),
    nextBillingDate: subscriptionItem.nextBillingDate.toLocaleDateString(
      "en-US",
      { year: "numeric", month: "short", day: "numeric" }
    ),
    totalCostIncurred: subscriptionItem.totalCostIncurred,
  };
  const subscriptionAccount = accounts.find(
    (item) => item.id === subscriptionItem.accountId
  );

  const category = userCategories.find(
    (item) => item.label === subscriptionItem.category
  );

  let frequencyLabel = "";
  switch (subscriptionData.frequency) {
    case "Weekly":
      frequencyLabel = "Week";
      break;
    case "Monthly":
      frequencyLabel = "Month";
      break;
    case "Quarterly":
      frequencyLabel = "Quarter";
      break;
    case "Annual":
      frequencyLabel = "Year";
      break;
    default:
      break;
  }

  const [moreOptionsShow, setMoreOptionsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editInput, setEditInput] = useState({
    imageUrl: subscriptionItem.imageUrl,
    name: subscriptionItem.name,
    vendor: subscriptionItem.vendor,
    description: subscriptionItem.description,
    accountId: subscriptionAccount?.id || "",
    category: subscriptionItem.category,
    paymentMethod: subscriptionItem.paymentMethod,
  });

  const moreOptionsRef = useRef();
  let [ref, { height }] = useMeasure();

  useEffect(() => {
    const handler = (e) => {
      if (!isEditing) {
        if (!moreOptionsRef.current.contains(e.target)) {
          setMoreOptionsShow(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [moreOptionsRef, isEditing]);

  const handleEditInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setEditInput((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleCancelEdit = (e) => {
    e.preventDefault();
    setEditInput({
      imageUrl: subscriptionItem.imageUrl,
      name: subscriptionItem.name,
      vendor: subscriptionItem.vendor,
      description: subscriptionItem.description,
      accountId: subscriptionItem.accountId,
      category: subscriptionItem.category,
      paymentMethod: subscriptionItem.paymentMethod,
    });
    setMoreOptionsShow(false);
    setIsEditing(false);
  };

  // update subscription doc for changes
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    //-------------------- demo mode only -------------------------------------
    if (!currentUser && onDemo) {
      const newObject = { ...subscriptionItem, ...editInput };
      setSubscriptionData((prevState) => {
        const newState = prevState.map((obj) => {
          let oldObject = { ...obj };
          if (obj.id === subscriptionItem.id) {
            return newObject;
          } else {
            return oldObject;
          }
        });
        return newState;
      });

      setIsLoading(false);
      setMoreOptionsShow(false);
      setIsEditing(false);
      return; //exit function for demo mode only
    } //-------------------- demo mode only -------------------------------------

    try {
      await updateDoc(doc(db, "subscription", subscriptionItem.id), {
        ...editInput,
      });

      const newObject = { ...subscriptionItem, ...editInput };
      setSubscriptionData((prevState) => {
        const newState = prevState.map((obj) => {
          let oldObject = { ...obj };
          if (obj.id === subscriptionItem.id) {
            return newObject;
          } else {
            return oldObject;
          }
        });
        return newState;
      });
      setIsLoading(false);
      setMoreOptionsShow(false);
      setIsEditing(false);
    } catch (err) {}
  };

  return (
    <div className="subscriptionwidget">
      <motion.div
        animate={{
          height: height,
          transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
        }}
        className="overflowHidden"
      >
        <form onSubmit={handleUpdate} ref={ref}>
          <div className="top">
            <div className="title">
              {!isEditing && (
                <>
                  <div className="subscriptionIconContainer">
                    <img src={subscriptionData.imageUrl} alt="" />
                  </div>
                  <div className="subscriptionName">
                    {subscriptionData.name}
                  </div>
                </>
              )}
              {isEditing && (
                <>
                  <div className="roundBackground">
                    <img src={editInput.imageUrl} alt="" />
                  </div>
                  <div className="inputsTop">
                    <input
                      placeholder="Subscription Name"
                      id="name"
                      value={editInput["name"]}
                      onChange={handleEditInput}
                      required
                    />
                    <input
                      placeholder="Image url..."
                      id="imageUrl"
                      value={editInput["imageUrl"]}
                      onChange={handleEditInput}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="details">
              <div className="subscriptionDetails">
                {!isEditing && (
                  <>
                    <div className="subscriptionVendor">
                      {subscriptionData.vendor}
                    </div>
                    <div className="subscriptionNotes">
                      {subscriptionData.description}
                    </div>
                    <div className="row">
                      <div
                        className="subscriptionAccount"
                        style={{
                          color: subscriptionAccount?.color
                            ? shadeColor(
                                subscriptionAccount?.color,
                                isDarkMode ? 20 : -20
                              )
                            : "",
                          backgroundColor: subscriptionAccount?.color + "1A",
                          border: `1px solid ${subscriptionAccount?.color}33`,
                        }}
                      >
                        {subscriptionAccount?.name || ""}
                      </div>
                      <div className="subscriptionCategory">
                        {`${category.icon} ${category.label}`}
                      </div>
                    </div>
                  </>
                )}
                {isEditing && (
                  <>
                    <input
                      placeholder="Vendor"
                      id="vendor"
                      value={editInput["vendor"]}
                      onChange={handleEditInput}
                      required
                    />
                    <textarea
                      className="description"
                      rows={2}
                      placeholder="Description..."
                      id="description"
                      value={editInput["description"]}
                      onChange={handleEditInput}
                    />
                    <div className="row">
                      <select
                        id="accountId"
                        name="accountId"
                        value={editInput["accountId"]}
                        onChange={handleEditInput}
                        required
                      >
                        <option disabled value="">
                          {" "}
                          -- select account --{" "}
                        </option>
                        {accounts.map((account) => (
                          <option value={account.id} key={account.id}>
                            {account.name}
                          </option>
                        ))}
                      </select>
                      <select
                        id="category"
                        name="category"
                        value={editInput["category"]}
                        onChange={handleEditInput}
                        required
                      >
                        <option disabled value="">
                          {" "}
                          -- select category --{" "}
                        </option>
                        {userCategories.map(
                          (category, index) =>
                            !category.hidden && (
                              <option
                                key={`${category.label}.${index}`}
                                value={category.label}
                              >
                                {`${category.icon} ${category.label}`}
                              </option>
                            )
                        )}
                      </select>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="row">
              <div className="left">
                {!isEditing && (
                  <div className="paymentMethod">
                    {subscriptionData.paymentMethod}
                  </div>
                )}
                {isEditing && (
                  <input
                    placeholder="e.g. Paypal"
                    id="paymentMethod"
                    value={editInput["paymentMethod"]}
                    onChange={handleEditInput}
                    required
                  />
                )}
              </div>
              <div className="right">
                <div className="subscriptionAmount">
                  <span className="currency">{currency.symbol}</span>
                  {subscriptionData.cost?.toLocaleString("en-us")}
                </div>
                <div className="frequency">/{frequencyLabel}</div>
              </div>
            </div>
            <div className="row">
              <div className="left">
                <div className="startDate">
                  Renewal:
                  <span>{subscriptionData.nextBillingDate}</span>
                </div>
              </div>
              <div className="right">
                <div className="totalCost">
                  <span className="currency">{currency.symbol}</span>
                  <span>
                    {subscriptionData.totalCostIncurred?.toLocaleString(
                      "en-us"
                    )}
                  </span>
                  <div className="label">Total Spent</div>
                </div>
              </div>
            </div>
            {isEditing && (
              <div className="buttons">
                <button
                  className="submitButton"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={"1.275rem"} className="spinner" />
                  ) : (
                    <>
                      <CheckOutlined className="icon" />
                    </>
                  )}
                </button>
                <button className="cancelButton" onClick={handleCancelEdit}>
                  <NotInterestedOutlined className="icon" />
                </button>
              </div>
            )}
          </div>
        </form>
        {!isEditing && (
          <div className="optionsContainer">
            <div
              className="iconContainer"
              onClick={() => setMoreOptionsShow((prevState) => !prevState)}
            >
              <MoreVertOutlined className="icon" />
            </div>
            <div className="relativeContainer">
              <div
                className={`dropdownOptions ${moreOptionsShow ? "show" : ""}`}
                ref={moreOptionsRef}
              >
                <div className="item" onClick={() => setIsEditing(true)}>
                  <ModeEditOutlineOutlined className="icon" />
                  <span>Edit</span>
                </div>
                <div
                  className="item"
                  onClick={() =>
                    setConfirmDelete({
                      showMessage: true,
                      id: subscriptionData.id,
                      name: subscriptionData.name,
                    })
                  }
                >
                  <DeleteOutlineOutlined className="icon" />
                  <span>Delete</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default SubscriptionWidget;
