import "./lazyloading.scss"
import iconLogo from "../../images/iconLogo.webp"
import { CircularProgress } from "@mui/material"
const LazyLoading = () => {
  return (
    <div className='lazyloading'>
        <div className="logoContainer">
            <img src={iconLogo} alt="" className='logoImage'/>
        </div>
        <CircularProgress size={"1.275rem"} className='spinner'/>
    </div>
  )
}

export default LazyLoading