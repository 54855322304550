import {
  createUserWithEmailAndPassword,
  deleteUser,
  EmailAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { createContext } from "react";
import { auth, db } from "../firebase";
import LazyLoading from "../components/lazyloading/LazyLoading";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [currentUserInfo, setCurrentUserInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [generatingDocs, setGeneratingDocs] = useState(false);
  const [onDemo, setOnDemo] = useState(false);

  const signup = async (email, password, username) => {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    setGeneratingDocs(true);
    const newUserDoc = {
      userId: res.user.uid,
      username: username,
      email: email,
      membershipType: "basic",
      created: serverTimestamp(),
    };
    await setDoc(doc(db, "users", res.user.uid), newUserDoc);

    // create default  project
    const newDocRef = doc(collection(db, "userProjects"));
    await setDoc(newDocRef, {
      userId: res.user.uid,
      name: "My Vacation Trip",
      sheetId: newDocRef.id,
    });

    await setDoc(doc(db, "userSettings", res.user.uid), {
      userId: res.user.uid,
    });

    // create default account
    const defaultAccountRef = doc(collection(db, "accounts"));
    await setDoc(defaultAccountRef, {
      name: "Cash",
      group: "Cash",
      beginningBalance: 0,
      beginningBalanceDate: Timestamp.fromDate(new Date()),
      color: "#48BB78",
      userId: res.user.uid,
    });
    setCurrentUserInfo(newUserDoc);
    setGeneratingDocs(false);
  };

  const login = async (email, password) => {
    return await signInWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    return signOut(auth);
  };

  const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  const updateUsername = (newUsername) => {
    return updateDoc(doc(db, "users", currentUser.uid), {
      username: newUsername,
    });
  };

  const reauthenticate = (password) => {
    const credential = EmailAuthProvider.credential(
      auth.currentUser?.email,
      password
    );
    return reauthenticateWithCredential(auth.currentUser, credential);
  };

  const updateCurrentEmail = (newEmail) => {
    return updateEmail(auth.currentUser, newEmail);
  };

  const updateCurrentPassword = (newPassword) => {
    return updatePassword(auth.currentUser, newPassword);
  };

  const deleteAccount = () => {
    deleteUser(auth.currentUser);
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userInfo = await getDoc(doc(db, "users", user.uid));
          setCurrentUserInfo(userInfo.data());
          setCurrentUser(user);
        } catch (err) {
          console.log(err);
        }
      } else {
        setCurrentUser(user); //null
        setCurrentUserInfo();
      }
      setOnDemo(false);
      setLoading(false);
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        currentUserInfo,
        setCurrentUserInfo,
        signup,
        login,
        logout,
        resetPassword,
        updateUsername,
        reauthenticate,
        updateCurrentEmail,
        updateCurrentPassword,
        deleteAccount,
        onDemo,
        setOnDemo,
      }}
    >
      {!loading && !generatingDocs ? children : <LazyLoading />}
    </AuthContext.Provider>
  );
};
