import { useEffect, useState } from "react";
import CategoryIcon from "../icons/CategoryIcon";
import "./budgetprogress.scss";
import { useSelector } from "react-redux";
import { getActualTotalFromArray } from "../../utils/utils";
import { motion } from "framer-motion";
import EmojiIcon from "../icons/EmojiIcon";

const BudgetProgress = (props) => {
  const { category, budgetData, handleEdit, data } = props;
  const currency = useSelector((state) => state.userSettings.currency);
  const [budget, setBudget] = useState({
    widthStyle: "0%",
    totalAmount: 0,
    totalSpent: 0,
    totalLeft: 0,
  });

  const { year, month, periodicDisplay } = useSelector(
    (state) => state.userSettings.options
  );

  const categoryBudget = budgetData.find((item) => {
    return item.category.toUpperCase() === category.label.toUpperCase();
  });
  const categoryActual = data.filter((item) => {
    if (item.category) {
      return item.category.toUpperCase() === category.label.toUpperCase();
    }
  });

  useEffect(() => {
    const actualTotal = getActualTotalFromArray(
      categoryActual,
      month,
      year,
      periodicDisplay
    );

    // setActualAmount(actualTotal)
    if (categoryBudget) {
      //yearly
      if (periodicDisplay === "yearly") {
        const perc = Math.min(
          Math.round(
            ((actualTotal / categoryBudget.totalAmount) * 100 * 100) / 100
          ),
          100
        );

        setBudget({
          ...budget,
          widthStyle: perc.toString() + "%",
          totalAmount: categoryBudget.totalAmount,
          totalSpent: actualTotal,
          totalLeft: Math.max(categoryBudget.totalAmount - actualTotal, 0),
        });
        //monthly
      } else {
        const monthAmount = categoryBudget.amount[month];
        // there is a budget on that month
        if (monthAmount) {
          const perc = Math.min(
            Math.round(((actualTotal / monthAmount) * 100 * 100) / 100),
            100
          );

          setBudget({
            ...budget,
            widthStyle: perc.toString() + "%",
            totalAmount: monthAmount,
            totalSpent: actualTotal,
            totalLeft: Math.max(monthAmount - actualTotal, 0),
          });
          // there is no budget on that month
        } else {
          let perc = 0;
          // if (actualTotal > 1) {
          //     perc = 100
          // }
          setBudget({
            ...budget,
            widthStyle: perc.toString() + "%",
            totalAmount: 0,
            totalSpent: actualTotal,
            totalLeft: 0,
          });
        }
      }
    } else {
      let perc = 0;
      // if (actualTotal > 1) {
      //     perc = 100
      // }
      setBudget({
        ...budget,
        widthStyle: perc.toString() + "%",
        totalAmount: 0,
        totalSpent: actualTotal,
        totalLeft: 0,
      });
    }
  }, [budgetData, year, month, periodicDisplay]);

  return (
    <div
      className="budgetprogress"
      onClick={() => handleEdit(categoryBudget, category.label)}
    >
      <div className="categoryTitle">
        <div className="categoryIcon">
          {category && <EmojiIcon emoji={category.icon} />}
        </div>
        <div className="categoryName">{category.label}</div>
      </div>
      <div className="progress">
        <motion.div
          initial={{ width: 0 }}
          animate={{
            width: `calc(${budget.widthStyle} - 4px)`,
            transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
          }}
          className={`progressBar ${
            budget.totalSpent > budget.totalAmount
              ? "negative"
              : budget.totalSpent / budget.totalAmount > 0.9
              ? "nearLimit"
              : "positive"
          }`}
        ></motion.div>
      </div>
      <div className="categoryAmount">
        <div className="amount">
          {budget.totalAmount !== 0 ? (
            <>
              <span className="currency">{currency.symbol}</span>
              {budget.totalLeft.toLocaleString("en-us")}
              <span className="amountLabel">left</span>
            </>
          ) : (
            <>-</>
          )}
        </div>
        <div className="amountUsed">
          {budget.totalAmount !== 0 ? (
            <div className="row">
              <div
                className={`totalSpent ${
                  budget.totalSpent > budget.totalAmount
                    ? "negative"
                    : "positive"
                }`}
              >
                <span className="currency">{currency.symbol}</span>
                <div className="totalSpentAmount">
                  {budget.totalSpent.toLocaleString("en-us")}
                </div>
              </div>
              <div className="separator">of</div>
              <div className="totalBudget">
                <span className="currency">{currency.symbol}</span>
                <div className="totalBudgetAmount">
                  {budget.totalAmount.toLocaleString("en-us")}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                className={`setUp ${
                  budget.totalSpent > 0 ? "withActualAmount" : ""
                }`}
              >
                Set up budget.
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BudgetProgress;
